<script lang="ts" setup>
withDefaults(
  defineProps<{
    columnCount?: 1 | 2;
    hasFullHeight?: boolean;
    hasSeparator?: boolean;
  }>(),
  {
    columnCount: 1,
    hasFullHeight: false,
    hasSeparator: false,
  },
);

const slots = defineSlots<{
  column1(): void;
  column2(): void;
}>();
</script>

<template>
  <div
    class="column-layout"
    :data-has-full-height="hasFullHeight"
    :data-has-separator="hasSeparator"
  >
    <div
      v-for="index in columnCount"
      :key="index"
      class="column-layout--column"
    >
      <slot
        v-if="slots[`column${index}` as keyof typeof slots]"
        :name="`column${index}` as keyof typeof slots"
      ></slot>
    </div>
  </div>
</template>

<style scoped>
@import "ColumnLayout.css";
</style>
