import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useNotifications } from "@/shared/composables";
import type { RegistrationFormData } from "@/app/registration-app/user/registration/@types";
import { registerUser } from "@/app/registration-app/user/registration/services";

export const useRegisterUser = (onActionFinished: () => void) => {
  const { t } = useI18n();
  const { addSuccessSnackbar, addErrorSnackbar } = useNotifications();

  const isRegisteringUser = ref(false);

  const onRegisteringUser = async (registrationData: RegistrationFormData) => {
    try {
      isRegisteringUser.value = true;
      await registerUser({
        companyName: registrationData.companyName,
        salutation: registrationData.salutation,
        firstName: registrationData.firstName,
        lastName: registrationData.lastName,
        email: registrationData.email,
        password: registrationData.password,
        newsletterConsent: registrationData.newsletter,
        captcha: registrationData.captcha,
      });
      addSuccessSnackbar(t("registration.shared.successNotification"), 2000);
      onActionFinished();
    } catch (error) {
      addErrorSnackbar(error as string);
    } finally {
      isRegisteringUser.value = false;
    }
  };

  return {
    isRegisteringUser,
    onRegisteringUser,
  };
};
