import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import { string as yupString } from "yup";

export function usePasswordField() {
  const { t } = useI18n();
  const possibleErrors = [t("shared.form.validation.min", { count: 10 })];
  const password = useField<string>(
    "password",
    yupString().min(10, possibleErrors[0]).required(),
    {
      initialValue: "",
    },
  );

  return { password, possibleErrors };
}
