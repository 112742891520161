<script lang="ts" setup>
import { computed, type HTMLAttributes } from "vue";
import { NumericUnit } from "@/shared/@types";
import { useNumericStepper, StepperType } from "./useNumericStepper.composable";
import NumericField from "../NumericField/NumericField.vue";
import Icon from "../Icon/Icon.vue";

const props = withDefaults(
  defineProps<{
    modelValue: string;
    errors?: Array<string>;
    label: string;
    disabled?: boolean;
    step?: number;
    separateInputStep?: number;
    inputmode?: HTMLAttributes["inputmode"];
    min?: number;
    max?: number;
    unit?: NumericUnit;
  }>(),
  {
    errors: () => [],
    disabled: false,
    step: 1.0,
    min: 0.0,
    unit: NumericUnit.Default,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const currentCount = computed(() => props.modelValue);
const { isMinimum, isMaximum, start, stop } = useNumericStepper(
  currentCount,
  props.min,
  props.max,
);

const hasUnit = computed(() => props.unit !== NumericUnit.Default);

const sanitizeNumericStep = (step: number) => Math.round(step * 100) / 100;

const increase = () => {
  emit(
    "update:modelValue",
    String(
      sanitizeNumericStep((parseFloat(props.modelValue) ?? 0) + props.step),
    ),
  );
};

const decrease = () => {
  emit(
    "update:modelValue",
    String(
      sanitizeNumericStep(
        (parseFloat(props.modelValue) ?? props.step) - props.step,
      ),
    ),
  );
};
</script>

<template>
  <NumericField
    :model-value="String(modelValue)"
    :label="label"
    :errors="errors"
    :disabled="disabled"
    :step="separateInputStep ? separateInputStep : step"
    :inputmode="inputmode"
    type="number"
    data-testid="numericStepperField"
    @update:model-value="$emit('update:modelValue', $event)"
    @mouseleave="stop()"
    @blur="stop()"
  >
    <template #append>
      <div class="numeric-stepper-field--append">
        <span
          v-if="hasUnit"
          class="numeric-stepper-field--unit"
          >{{ unit }}</span
        >
        <div class="numeric-stepper-field--stepper">
          <button
            class="numeric-stepper-field--button"
            :disabled="isMaximum"
            type="button"
            :aria-label="$t('shared.form.field.numericStepper.increaseValue')"
            @mousedown="start(StepperType.Increase, $event, increase)"
            @mouseup="stop()"
          >
            <Icon
              icon="chevronUpM"
              class="numeric-stepper-field--button-icon"
            />
          </button>
          <button
            class="numeric-stepper-field--button"
            type="button"
            :aria-label="$t('shared.form.field.numericStepper.decreaseValue')"
            :disabled="isMinimum"
            @mousedown="start(StepperType.Decrease, $event, decrease)"
            @mouseup="stop()"
          >
            <Icon
              icon="chevronDownM"
              class="numeric-stepper-field--button-icon"
            />
          </button>
        </div>
      </div>
    </template>
  </NumericField>
</template>

<style scoped>
@import "NumericStepperField";
</style>
