export enum IconEnum {
  acUnit = "ac_unit",
  accountCircle = "account_circle",
  add = "add",
  addCircle = "add_circle",
  addLocation = "add_location",
  addNotification = "add_notification",
  addToHomescreen = "add_to_homescreen",
  airFreshener = "air_freshener",
  approvalDelegation = "approval_delegation",
  apps = "apps",
  arrowBack = "arrow_back",
  arrowDownward = "arrow_downward",
  arrowDownwardAlt = "arrow_downward_alt",
  arrowDropDown = "arrow_drop_down",
  arrowDropUp = "arrow_drop_up",
  arrowForward = "arrow_forward",
  arrowLeftAlt = "arrow_left_alt",
  arrowRightAlt = "arrow_right_alt",
  arrowUpward = "arrow_upward",
  arrowUpwardAlt = "arrow_upward_alt",
  avTimer = "av_timer",
  backwardMedia = "backward_media",
  bank1 = "bank_1",
  barcodeScanner = "barcode_scanner",
  blog = "blog",
  bookmark = "bookmark",
  bookmarkAdd = "bookmark_add",
  build = "build",
  calendarFilled = "calendar_filled",
  calendarMonth = "calendar_month",
  calendarToday = "calendar_today",
  call = "call",
  camera = "camera",
  cameraswitch = "cameraswitch",
  cancel = "cancel",
  cancelFilled = "cancel_filled",
  card = "card",
  career = "career",
  change = "change",
  changePayment = "change_payment",
  check = "check",
  checkBoxChecked = "check_box_checked",
  checkBoxUnchecked = "check_box_unchecked",
  checkCircle = "check_circle",
  checkCircleFilled = "check_circle_filled",
  checkCircleOpen = "check_circle_open",
  checkIndeterminateSmall = "check_indeterminate_small",
  chevronDownM = "chevron_down_m",
  chevronLeftM = "chevron_left_m",
  chevronLeftS = "chevron_left_s",
  chevronRightM = "chevron_right_m",
  chevronRightS = "chevron_right_s",
  chevronUpM = "chevron_up_m",
  close = "close",
  copy = "copy",
  corporate = "corporate",
  corporateFare = "corporate_fare",
  cottage = "cottage",
  dateRange = "date_range",
  delete = "delete",
  description = "description",
  directions = "directions",
  dot = "dot",
  doublechevronLeft = "doublechevron_left",
  doublechevronRight = "doublechevron_right",
  download = "download",
  easyMove = "easy_move",
  edit = "edit",
  emergencyHome = "emergency_home",
  emergencyHomeFilled = "emergency_home_filled",
  errorCircleRounded = "error_circle_rounded",
  errorFilled = "error_filled",
  evCharge = "ev_charge",
  exclamationMark = "exclamation_mark",
  expandLess = "expand_less",
  expandMore = "expand_more",
  expert = "expert",
  fastCheckout = "fast_checkout",
  favorite = "favorite",
  favoriteFilled = "favorite_filled",
  fillibri = "fillibri",
  filter = "filter",
  filterAlt = "filter_alt",
  flaschenbestand = "flaschenbestand",
  forwardMedia = "forward_media",
  gas = "gas",
  heartBeat = "heart_beat",
  help = "help",
  helpFilled = "help_filled",
  home = "home",
  image = "image",
  info = "info",
  infoFilled = "info_filled",
  inventory2 = "inventory_2",
  keyboard = "keyboard",
  label = "label",
  language = "language",
  languageFilled = "language_filled",
  list = "list",
  localCarWash = "local_car_wash",
  localGasStation = "local_gas_station",
  localMall = "local_mall",
  locationOn = "location_on",
  lock = "lock",
  login = "login",
  logout = "logout",
  mail = "mail",
  markEmailUnread = "mark_email_unread",
  markUnreadChatAlt = "mark_unread_chat_alt",
  menu = "menu",
  modeHeat = "mode_heat",
  moreHoriz = "more_horiz",
  moreVert = "more_vert",
  myLocation = "my_location",
  myLocationFilled = "my_location_filled",
  newLabel = "new_label",
  news = "news",
  notifications = "notifications",
  notificationsActive = "notifications_active",
  openInNew = "open_in_new",
  package = "package",
  pauseCircle = "pause_circle",
  pinDrop = "pin_drop",
  playCircle = "play_circle",
  post = "post",
  print = "print",
  propane = "propane",
  propaneTank = "propane_tank",
  propaneTankFilled = "propane_tank_filled",
  publicFilled = "public_filled",
  publishedWithChanges = "published_with_changes",
  qrCodeScanner = "qr_code_scanner",
  redo = "redo",
  refresh = "refresh",
  repayment = "repayment",
  replay = "replay",
  replay10 = "replay_10",
  reportMeter = "report_meter",
  restaurant = "restaurant",
  return = "return",
  review = "review",
  router = "router",
  schedule = "schedule",
  screenRotation = "screen_rotation",
  search = "search",
  searchOff = "search_off",
  services = "services",
  settings = "settings",
  shoppingCart = "shopping_cart",
  smartphone = "smartphone",
  smartphoneFilled = "smartphone_filled",
  speed = "speed",
  ssidChart = "ssid_chart",
  star = "star",
  starFilled = "star_filled",
  statistics = "statistics",
  teamDashboard = "team_dashboard",
  tile = "tile",
  toggleOff = "toggle_off",
  toggleOn = "toggle_on",
  transaction = "transaction",
  user = "user",
  visibility = "visibility",
  visibilityOff = "visibility_off",
  volumeOff = "volume_off",
  volumeUp = "volume_up",
  westfalengasBio = "westfalengas_bio",
  zoomIn = "zoom_in",
  zoomInMap = "zoom_in_map",
  zoomOut = "zoom_out",
  zoomOutMap = "zoom_out_map",
}

export type IconName = keyof typeof IconEnum;
