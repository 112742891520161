import { createClient } from "@/shared/urql/urql";
import {
  VerifyEmailDocument,
  type VerifyEmailMutationVariables,
} from "@/shared/generated/graphql";
import { executeMutation } from "@/shared/helper";

export const verifyEmail = async ({
  email,
  token,
}: VerifyEmailMutationVariables): Promise<void> => {
  const urqlClient = createClient();
  const error = await executeMutation(
    (data) => data?.verifyEmail,
    () =>
      urqlClient.mutation(VerifyEmailDocument, { email, token }).toPromise(),
  );
  if (error) throw error;
};
