import { i18n } from "@/shared/i18n/i18n";

export const serviceLinks = {
  contact: {
    caption: i18n.t("shared.corporate.service.link.contact.title"),
    path: i18n.t("shared.corporate.service.link.contact.path"),
  },
  privacy: {
    caption: i18n.t("shared.corporate.service.link.privacy.title.default"),
    path: i18n.t("shared.corporate.service.link.privacy.path"),
  },
  cancellation: {
    caption: i18n.t("shared.corporate.service.link.cancellation.title.default"),
    path: i18n.t("shared.corporate.service.link.cancellation.path"),
  },
  imprint: {
    caption: i18n.t("shared.corporate.service.link.imprint.title"),
    path: i18n.t("shared.corporate.service.link.imprint.path"),
  },
  conditions: {
    caption: i18n.t("shared.corporate.service.link.conditions.title"),
    path: i18n.t("shared.corporate.service.link.conditions.path"),
  },
  personalData: {
    caption: i18n.t("shared.corporate.service.link.personalData.title.default"),
    path: i18n.t("shared.corporate.service.link.personalData.path"),
  },
};
