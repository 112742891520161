import { createClient } from "@/shared/urql/urql";
import { GetRegistrationDataDocument } from "@/shared/generated";

export const getRegistrationData = async (provisioningKey: string) => {
  const client = createClient();

  const { error, data } = await client
    .query(GetRegistrationDataDocument, { provisioningKey })
    .toPromise();
  const errors = data?.registrationData?.errors;

  if (error) throw error.message;
  if (errors && errors.length > 0) throw errors[0];
  if (!data) throw "INTERNAL_ERROR";

  return data.registrationData?.registrationData || undefined;
};
