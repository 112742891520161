<script setup lang="ts">
import { ColumnLayout, ColumnLayoutBox } from "@/shared/components";
import { useRoute } from "vue-router";
import { StatusMessagePage } from "@/app/registration-app/shared/components";
import { RegistrationFormContainer } from "@/app/registration-app/user/invited-registration/containers";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const route = useRoute();
const provisioningKey = route.query.provisioningKey as string;

// --- Status Message ---
const statusMessageTitle = ref("");
const statusMessageText = ref<string | undefined>(undefined);
const setStatusMessage = (title: string, text?: string) => {
  statusMessageTitle.value = title;
  statusMessageText.value = text;
};

onMounted(() => {
  if (!provisioningKey)
    setStatusMessage(
      t("registration.user.invited.error.invalidLink.title"),
      t("registration.user.invited.error.invalidLink.text"),
    );
});
</script>

<template>
  <StatusMessagePage
    v-if="statusMessageTitle"
    :title="statusMessageTitle"
    :text="statusMessageText"
  />
  <ColumnLayout v-else>
    <template #column1>
      <ColumnLayoutBox center-content="both">
        <RegistrationFormContainer
          :provisioning-key="provisioningKey"
          @show-success="
            setStatusMessage(
              $t('registration.user.invited.success.title'),
              $t('registration.user.invited.success.text'),
            )
          "
          @show-error="setStatusMessage($event.title, $event.text)"
        />
      </ColumnLayoutBox>
    </template>
  </ColumnLayout>
</template>
