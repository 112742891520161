<script lang="ts" setup>
import { type HTMLAttributes } from "vue";
import { getUniqueId } from "@/shared/helper";
import InputGroup from "../InputGroup/InputGroup.vue";

withDefaults(
  defineProps<{
    modelValue: string;
    label: string;
    required?: boolean;
    errors?: Array<string>;
    name?: string;
    hint?: string;
    disabled?: boolean;
    autocomplete?: string;
    step?: number;
    inputmode: HTMLAttributes["inputmode"];
  }>(),
  {
    errors: () => [],
    disabled: false,
  },
);

defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const inputId = getUniqueId("numeric-input");
</script>

<template>
  <InputGroup
    :id="inputId"
    :label="label"
    :hint="hint"
    :errors="errors"
    :is-disabled="disabled"
    :is-required="required"
  >
    <template #default="{ inputBindings }">
      <input
        :id="inputId"
        :value="modelValue"
        type="number"
        :name="name"
        :disabled="disabled"
        :required="required"
        :autocomplete="autocomplete"
        :step="step"
        :inputmode="inputmode"
        v-bind="inputBindings"
        @input="
          $emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
      />
    </template>
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append"></slot>
    </template>
    <template
      v-if="$slots.errorMessage"
      #errorMessage="{ errorMessageId }"
    >
      <slot
        name="errorMessage"
        :error-message-id="errorMessageId"
      ></slot>
    </template>
  </InputGroup>
</template>
