import shared from "./shared";
import dashboard from "./dashboard";
import account from "./account";
import widAccountManagement from "./wid-account-management";
import fbsHub from "./fbs-hub";
import fleetAtHome from "./fleet-at-home";
import fleetAtHomeManagement from "./fleet-at-home-management";
import loginCallback from "./login-callback";
import wccManagement from "./wcc-management";
import wscManagement from "./wsc-management";
import thgQuote from "./thg-quote";
import registration from "./registration";
import salesforcePages from "./salesforce-pages";
import finishRegistration from "./finish-registration";

export const de = {
  shared,
  dashboard,
  account,
  widAccountManagement,
  fbsHub,
  fleetAtHome,
  fleetAtHomeManagement,
  loginCallback,
  wccManagement,
  wscManagement,
  thgQuote,
  registration,
  salesforcePages,
  finishRegistration,
};
