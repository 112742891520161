<script lang="ts" setup>
import { computed } from "vue";
import type { FormFieldset } from "@/shared/@types";

const props = withDefaults(
  defineProps<{
    id?: string;
    fieldsets?: Array<FormFieldset>;
    isClassicForm?: boolean;
    action?: string;
  }>(),
  {
    fieldsets: () => [{ slot: "default" }],
    isClassicForm: false,
  },
);

const usedSlots = defineSlots<{
  default(): void;
  actions(): void;
}>();

const hasActions = computed(() => !!usedSlots.actions);

const emit = defineEmits<{
  (e: "submit", value: Event): void;
}>();

const onSubmit = (event: Event) => {
  if (!props.isClassicForm) event.preventDefault();
  emit("submit", event);
};

const classicAttributes = computed(() =>
  props.isClassicForm ? { method: "post", action: props.action } : undefined,
);
</script>

<template>
  <form
    :id="id"
    class="form"
    v-bind="classicAttributes"
    @submit="onSubmit"
  >
    <fieldset
      v-for="fieldset in fieldsets"
      :key="fieldset.slot"
      class="form--section"
    >
      <legend
        v-if="fieldset.title"
        class="form--legend"
      >
        {{ fieldset.title }}
      </legend>
      <slot :name="fieldset.slot as keyof typeof usedSlots"></slot>
    </fieldset>
    <div
      v-if="hasActions"
      class="form--actions"
    >
      <slot name="actions"></slot>
    </div>
  </form>
</template>

<style scoped>
@import "Form.css";
</style>
