import { ThgQuoteDeadlineStatus } from "@/shared/@types";

export const getThgQuoteDeadlineStatus = (): ThgQuoteDeadlineStatus => {
  const now = new Date();
  if (now.getMonth() >= 10 || now.getMonth() <= 0)
    return ThgQuoteDeadlineStatus.closed;
  if (now.getMonth() === 9 && now.getDate() >= 17)
    return ThgQuoteDeadlineStatus.nearDeadline;
  return ThgQuoteDeadlineStatus.open;
};
