import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useField, useForm } from "vee-validate";
import {
  object as yupObject,
  string as yupString,
  boolean as yupBoolean,
  mixed,
} from "yup";
import { ExistingRegistrationSalutationInput } from "@/shared/generated";
import type { ExistingRegistrationFormData } from "@/app/registration-app/user/existing-registration/@types";
import { useExistingRegistrationSalutationField } from "@/shared/composables";
import { TEXTFIELD_MAX_LENGTH_DEFAULT } from "@/shared/helper";

export const useExistingRegistrationFields = () => {
  const { t } = useI18n();

  const validationSchema = computed(() => {
    return yupObject({
      salutation: mixed()
        .oneOf(Object.values(ExistingRegistrationSalutationInput))
        .required(t("shared.form.validation.required")),
      firstName: yupString()
        .trim()
        .required(t("shared.form.validation.required"))
        .max(
          TEXTFIELD_MAX_LENGTH_DEFAULT,
          t("shared.form.validation.max", {
            count: TEXTFIELD_MAX_LENGTH_DEFAULT,
          }),
        ),
      lastName: yupString()
        .trim()
        .required(t("shared.form.validation.required"))
        .max(
          TEXTFIELD_MAX_LENGTH_DEFAULT,
          t("shared.form.validation.max", {
            count: TEXTFIELD_MAX_LENGTH_DEFAULT,
          }),
        ),
      email: yupString()
        .trim()
        .email(t("shared.form.validation.email"))
        .required(t("shared.form.validation.required")),
      customerId: yupString()
        .required(t("shared.form.validation.required"))
        .matches(/^\d+$/, t("shared.form.validation.customerId"))
        .max(10, t("shared.form.validation.max", { count: 10 })),
      conditions: yupBoolean()
        .required(t("shared.form.validation.checked"))
        .oneOf([true], t("shared.form.validation.checked")),
      privacy: yupBoolean()
        .required(t("shared.form.validation.checked"))
        .oneOf([true], t("shared.form.validation.checked")),
      captcha: yupString().required(t("shared.form.validation.captcha")),
    });
  });

  const { handleSubmit, resetForm } = useForm<ExistingRegistrationFormData>({
    validationSchema,
  });

  const { salutation, salutationOptions } =
    useExistingRegistrationSalutationField({
      required: false,
    });
  const firstName = useField<string>("firstName", undefined);
  const lastName = useField<string>("lastName", undefined);
  const email = useField<string>("email", undefined);
  const customerId = useField<string>("customerId", undefined);
  const conditions = useField<boolean>("conditions", undefined, {
    initialValue: false,
  });
  const privacy = useField<boolean>("privacy", undefined, {
    initialValue: false,
  });
  const captcha = useField<string>("captcha", undefined);

  return {
    handleSubmit,
    resetForm,
    salutation,
    salutationOptions,
    firstName,
    lastName,
    email,
    customerId,
    conditions,
    privacy,
    captcha,
  };
};
