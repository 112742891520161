import { createClient } from "@/shared/urql/urql";
import {
  RegisterUserDocument,
  type RegistrationDataInput,
} from "@/shared/generated/graphql";
import { executeMutation } from "@/shared/helper";

export const registerUser = async (registrationData: RegistrationDataInput) => {
  const urqlClient = createClient();

  const error = await executeMutation(
    (data) => data?.registerUser,
    () =>
      urqlClient
        .mutation(RegisterUserDocument, {
          registrationData,
        })
        .toPromise(),
  );
  if (error) throw error;
};
