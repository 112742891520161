import { type VerifyEmailMutationVariables } from "@/shared/generated/graphql";
import { ref } from "vue";
import { verifyEmail } from "../services/verifyEmail.service";

export const useVerifyEmail = () => {
  const isVerifyingEmail = ref(false);
  const verifyingEmailError = ref(false);

  const onVerifyEmail = async ({
    email,
    token,
  }: VerifyEmailMutationVariables) => {
    try {
      isVerifyingEmail.value = true;
      await verifyEmail({ email, token });
    } catch {
      verifyingEmailError.value = true;
    } finally {
      isVerifyingEmail.value = false;
    }
  };

  return {
    isVerifyingEmail,
    verifyingEmailError,
    onVerifyEmail,
  };
};
