<script lang="ts" setup>
import { computed, type HTMLAttributes } from "vue";
import type { IconName } from "@/shared/@types";
import { getUniqueId } from "@/shared/helper";
import InputGroup from "../InputGroup/InputGroup.vue";
import TextInputCounter from "../TextInputCounter/TextInputCounter.vue";

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    label: string;
    placeholder?: string;
    size?: "s" | "m" | "l";
    errors?: Array<string>;
    name?: string;
    type?: "date" | "email" | "password" | "text" | "search";
    prependIcon?: IconName;
    disabled?: boolean;
    autocomplete?: string;
    minlength?: number;
    maxlength?: number;
    showMaxlengthCounter?: boolean;
    required?: boolean;
    min?: string;
    max?: string;
    inputmode?: HTMLAttributes["inputmode"];
    isLabelHidden?: boolean;
    hint?: string;
    hasLinkedHint?: boolean;
  }>(),
  {
    size: "m",
    type: "text",
    showMaxlengthCounter: true,
    errors: () => [],
    disabled: false,
    isLabelHidden: false,
  },
);
defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "openHint"): void;
}>();

// --- Text Input Counter ---
const currentInputCount = computed(() => props.modelValue?.length || 0);

// to adjust the correct padding size of the input
// eg 30 / 30 => two times the characters of maxlength plus 1 character for the slash and two for the spaces
const styleBindings = computed(() => ({
  "--input-append-size": props.maxlength
    ? `${props.maxlength.toString().length * 2 + 3}ch`
    : undefined,
}));

const inputId = getUniqueId("text-input");
</script>

<template>
  <InputGroup
    :id="inputId"
    :label="label"
    :size="size"
    :hint="hint"
    :has-linked-hint="hasLinkedHint"
    :errors="errors"
    :is-disabled="disabled"
    :is-required="required"
    :is-label-hidden="isLabelHidden"
    :prepend-icon="prependIcon"
    :hide-error-append-icon="type === 'date'"
    @open-hint="$emit('openHint')"
  >
    <template #default="{ inputBindings }">
      <input
        :id="inputId"
        :value="modelValue"
        :placeholder="placeholder"
        :type="type"
        :name="name"
        :disabled="disabled"
        :required="required"
        :autocomplete="autocomplete"
        :maxlength="maxlength"
        :min="min"
        :max="max"
        :inputmode="inputmode"
        v-bind="inputBindings"
        :style="styleBindings"
        @input="
          $emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
      />
    </template>
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append"></slot>
    </template>
    <template
      v-if="maxlength && showMaxlengthCounter"
      #label-append
    >
      <div ref="textInputCounter">
        <TextInputCounter
          :current-value="currentInputCount"
          :max-value="maxlength"
        />
      </div>
    </template>
    <template
      v-if="$slots.errorMessage"
      #errorMessage="{ errorMessageId }"
    >
      <slot
        name="errorMessage"
        :error-message-id="errorMessageId"
      ></slot>
    </template>
  </InputGroup>
</template>
