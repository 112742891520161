import { ref } from "vue";
import { useNotifications } from "@/shared/composables";
import type { ExistingRegistrationFormData } from "@/app/registration-app/user/existing-registration/@types";
import { registerExistingUser } from "@/app/registration-app/user/existing-registration/services";

export const useRegisterExistingUser = () => {
  const { addErrorSnackbar } = useNotifications();

  const isRegisteringExistingUser = ref(false);
  const caseNumber = ref<string>();

  const onRegisteringExistingUser = async (
    registrationData: ExistingRegistrationFormData,
  ) => {
    try {
      isRegisteringExistingUser.value = true;
      caseNumber.value = await registerExistingUser({
        salutation: registrationData.salutation,
        firstName: registrationData.firstName,
        lastName: registrationData.lastName,
        email: registrationData.email,
        customerId: registrationData.customerId,
        captcha: registrationData.captcha,
      });
    } catch (error) {
      addErrorSnackbar(error as string);
    } finally {
      isRegisteringExistingUser.value = false;
    }
  };

  return {
    caseNumber,
    isRegisteringExistingUser,
    onRegisteringExistingUser,
  };
};
