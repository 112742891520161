<script lang="ts" setup>
import { computed, toRefs, useSlots } from "vue";

const props = withDefaults(
  defineProps<{
    title: string;
    text?: string;
    size?: "xs" | "m";
  }>(),
  {
    size: "m",
  },
);

const { text } = toRefs(props);

const slots = useSlots();
const hasText = computed(() => !!slots.text || !!text?.value);
const hasActions = computed(() => !!slots.actions);
</script>

<template>
  <div
    class="status-message"
    :data-size="size"
  >
    <div class="status-message--inner">
      <h1 class="status-message--title">{{ title }}</h1>
      <div
        v-if="hasText"
        class="status-message--text"
      >
        <p v-if="text">{{ text }}</p>
        <slot name="text"></slot>
      </div>
      <div
        v-if="hasActions"
        class="status-message--actions"
      >
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "StatusMessage.css";
</style>
