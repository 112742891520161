import { AppRouteNames } from "@/app/registration-app/shared/@types";
import { AppIdentifier } from "@/shared/@types";
import { routes as userRoutes } from "@/app/registration-app/user/routes";
import PageNotFoundView from "@/app/registration-app/page-not-found/PageNotFoundView/PageNotFoundView.vue";

export const routes = [
  {
    path: "/registration",
    children: [...userRoutes],
  },
  {
    path: `/${AppIdentifier.FleetAtHome}/registration`,
    redirect: { name: AppRouteNames.InvitedUserRegistration },
  },
  {
    path: "/:pathMatch(.*)*",
    name: AppRouteNames.NotFound,
    component: PageNotFoundView,
  },
];
