<script setup lang="ts">
import { computed, onMounted } from "vue";
import VerifyEmailContainer from "../containers/VerifyEmailContainer/VerifyEmailContainer.vue";
import { useVerifyEmail } from "./useVerifyEmail.composable";

const { email, token } = defineProps<{ email?: string; token?: string }>();

const { isVerifyingEmail, verifyingEmailError, onVerifyEmail } =
  useVerifyEmail();

onMounted(() => {
  if (email && token) {
    onVerifyEmail({ email, token });
  }
});

const hasError = computed(() => verifyingEmailError.value || !email || !token);
</script>

<template>
  <VerifyEmailContainer
    :is-verifying-email="isVerifyingEmail"
    :has-error="hasError"
  />
</template>
