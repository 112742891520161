import {
  createClient as createUrqlClient,
  cacheExchange,
  fetchExchange,
  type ClientOptions,
  Client,
  mapExchange,
  makeOperation,
} from "@urql/vue";
import { parallelQueryExchange } from "./parallelQueryExchange";
import { getJwt } from "@/app/AuthService.ts";

export const createUrqlConfig = (): ClientOptions => {
  return {
    url: `${import.meta.env.VITE_DIGITAL_PORTAL_BACKEND_BASE_URL}/api/graphql`,
    requestPolicy: "network-only",
    exchanges: [
      mapExchange({
        async onOperation(operation) {
          const jwt = await getJwt();
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              headers: {
                ...(jwt && { Authorization: `Bearer ${jwt}` }),
              },
            },
          });
        },
      }),
      parallelQueryExchange,
      cacheExchange,
      fetchExchange,
    ],
  };
};

export const createClient = (): Client => createUrqlClient(createUrqlConfig());
