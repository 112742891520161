import { UserManager, WebStorageStateStore } from "oidc-client-ts";

const oidcConfig = {
  authority: import.meta.env.VITE_SALESFORCE_OIDC_ISSUER_URL,
  client_id: import.meta.env.VITE_SALESFORCE_OIDC_CLIENT_ID,
  redirect_uri: `${import.meta.env.VITE_BASE_URL}/auth/callback`,
  post_logout_redirect_uri: `${import.meta.env.VITE_BASE_URL}/`,
  response_type: "code",
  scope: "openid profile email refresh_token",
  refreshTokenAllowedScope: "",
  userStore: new WebStorageStateStore({ store: window.localStorage }), // ?
  code_challenge_method: "S256",
};

const userManager = new UserManager(oidcConfig);

export default userManager;
