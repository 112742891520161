import { useField } from "vee-validate";

import { string as yupString } from "yup";
import { useI18n } from "vue-i18n";

export function useEmailField(initialValue = "") {
  const { t } = useI18n();

  const email = useField<string>(
    "email",
    yupString()
      .trim()
      .email(t("shared.form.validation.email"))
      .required(t("shared.form.validation.required")),
    { initialValue },
  );

  return { email };
}
