<script lang="ts" setup>
import { computed, type ButtonHTMLAttributes } from "vue";
import type { RouteLocationRaw } from "vue-router";
import type { IconName } from "@/shared/@types";
import Icon from "../Icon/Icon.vue";
import LoadingDots from "../LoadingDots/LoadingDots.vue";

const props = withDefaults(
  defineProps<{
    caption: string;
    variant?:
      | "filled-darkblue"
      | "filled-red"
      | "filled-green"
      | "filled-white"
      | "danger"
      | "outlined-black"
      | "outlined-grey"
      | "outlined-white";
    size?: "xs" | "s" | "m" | "l" | "xl";
    type?: ButtonHTMLAttributes["type"];
    href?: string;
    target?: string;
    icon?: IconName;
    iconPosition?: "leading" | "trailing" | "icon-only";
    isLoading?: boolean;
    isDisabled?: boolean;
    to?: RouteLocationRaw;
  }>(),
  {
    variant: "filled-darkblue",
    size: "m",
    type: "button",
    iconPosition: "leading",
    isLoading: false,
    isDisabled: false,
  },
);

const component = computed(() => {
  if (props.to) {
    return "RouterLink";
  }
  if (props.href) {
    return "a";
  }
  return "button";
});

const conditionalAttributes = computed(() => {
  if (props.to) {
    return { to: props.to };
  }
  if (props.href) {
    return { href: props.href, target: props.target };
  }
  return { type: props.type };
});

const iconSize = computed(() => {
  if (props.size === "xs") return 18;
  if (props.size === "xl") return 24;
  return 20;
});

const iconOnly = computed(() => props.iconPosition === "icon-only");
</script>

<template>
  <component
    :is="component"
    class="button | antialiased"
    :disabled="isLoading || isDisabled"
    :data-variant="variant"
    :data-size="size"
    :data-icon-position="iconPosition"
    :data-has-icon="!!icon && !isLoading"
    v-bind="conditionalAttributes"
  >
    <span
      v-if="isLoading"
      class="button--loading"
    >
      <LoadingDots />
    </span>
    <Icon
      v-else-if="icon"
      :icon="icon"
      :size="iconSize"
    />
    <span
      class="button--caption"
      :class="{ 'sr-only': isLoading || iconOnly }"
    >
      {{ caption }}
    </span>
  </component>
</template>

<style scoped>
@import "Button.css";
</style>
