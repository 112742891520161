<script lang="ts" setup>
import { computed } from "vue";
import { getUniqueId } from "@/shared/helper";
import RadioCard from "../RadioCard/RadioCard.vue";

type RadioValue = HTMLInputElement["value"];
type RadioName = HTMLInputElement["name"];

const props = withDefaults(
  defineProps<{
    modelValue: RadioValue;
    name?: RadioName;
    options: Array<{
      value: RadioValue;
      label: string;
      description?: string;
      slot?: string;
    }>;
    errors?: Array<string>;
  }>(),
  {
    name: getUniqueId("radio-card-group"),
    errors: () => [],
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: RadioValue): void;
}>();

const hasErrors = computed(() => props.errors.length > 0);

const updateInput = (value: RadioValue) => emit("update:modelValue", value);
</script>

<template>
  <div
    class="radio-card-group"
    role="radiogroup"
  >
    <template
      v-for="option in options"
      :key="option.value"
    >
      <RadioCard
        :model-value="modelValue"
        :value="option.value"
        :label="option.label"
        :description="option.description"
        :name="name"
        :is-errored="hasErrors"
        @update:model-value="updateInput"
      >
        <template
          v-if="option.slot"
          #additionalText
        >
          <slot :name="option.slot"></slot>
        </template>
      </RadioCard>
    </template>
    <span
      v-if="hasErrors"
      class="radio-card-group--error-message"
    >
      {{ errors[0] }}
    </span>
  </div>
</template>

<style scoped>
@import "RadioCardGroup.css";
</style>
