<script lang="ts" setup>
import { toRefs } from "vue";
import { Modal, Button } from "@/shared/components";
import invoiceUrl from "@/shared/images/invoice.svg?url";

const props = defineProps<{
  isVisible: boolean;
}>();

const { isVisible } = toRefs(props);

defineEmits<{
  (e: "cancel"): void;
}>();
</script>

<template>
  <Modal
    :is-visible="isVisible"
    :title="$t('registration.user.existing.helpModal.title')"
    has-cancel-button
    @cancel="$emit('cancel')"
  >
    <template #main>
      <span>{{ $t("registration.user.existing.helpModal.text") }}</span>
      <picture class="help-modal--image-wrapper">
        <img
          class="help-modal--image"
          :src="invoiceUrl"
          :alt="$t('shared.term.billing.title')"
        />
      </picture>
    </template>
    <template #actions>
      <Button
        :caption="$t('shared.action.confirm.ok')"
        @click="$emit('cancel')"
      />
    </template>
  </Modal>
</template>
