<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useElementSize, useMagicKeys, useScroll } from "@vueuse/core";
import Overlay from "../Overlay/Overlay.vue";
import Icon from "../Icon/Icon.vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    title: string;
    hasCancelButton?: boolean;
    hasError?: boolean;
  }>(),
  {
    hasCancelButton: false,
    hasError: false,
  },
);

const emit = defineEmits<{
  (e: "cancel"): void;
}>();

const modalMain = ref<HTMLDivElement | null>(null);
const modalContent = ref<HTMLDivElement | null>(null);

const { height: mainHeight } = useElementSize(modalMain);
const { height: contentHeight } = useElementSize(modalContent);

const { arrivedState } = useScroll(modalMain);

const showScrollFade = computed(() => {
  return mainHeight.value < contentHeight.value && !arrivedState.bottom;
});

const keys = useMagicKeys();
watch(keys.Escape, (stroke) => {
  if (stroke && props.hasCancelButton) emit("cancel");
});
</script>

<template>
  <Overlay :is-visible="isVisible">
    <div
      class="modal"
      :data-scroll="showScrollFade ? 'on' : 'off'"
      data-testid="modal"
    >
      <header
        class="modal--header"
        :data-has-error="hasError"
      >
        <h2>{{ title }}</h2>
        <button
          v-if="hasCancelButton"
          type="button"
          :aria-label="$t('shared.action.close')"
          @click="$emit('cancel')"
        >
          <Icon icon="close" />
        </button>
      </header>
      <div
        ref="modalMain"
        class="modal--main"
      >
        <div
          ref="modalContent"
          class="modal--content"
        >
          <slot name="main"></slot>
        </div>
      </div>
      <div class="modal--bottom">
        <footer
          v-if="$slots.actions"
          class="modal--footer"
        >
          <slot name="actions"></slot>
        </footer>
      </div>
    </div>
  </Overlay>
</template>

<style scoped>
@import "Modal.css";
</style>
