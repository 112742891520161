<script setup lang="ts">
import { watch, toRefs } from "vue";
import {
  TextField,
  PasswordField,
  Button,
  SelectField,
  Form,
  PermissionChecks,
} from "@/shared/components";
import { TEXTFIELD_MAX_LENGTH_DEFAULT } from "@/shared/helper";
import type {
  RegistrationFormData,
  InitialRegistrationData,
} from "@/app/registration-app/user/invited-registration/@types";
import { useRegistrationDataFields } from "./useRegistrationDataFields.composable";

const props = withDefaults(
  defineProps<{
    initialValues: InitialRegistrationData | undefined;
    isLoading?: boolean;
  }>(),
  {
    isLoading: false,
  },
);

const { initialValues } = toRefs(props);

const emit = defineEmits<{
  (e: "register", fields: RegistrationFormData): void;
}>();

// --- Get Form-Fields ---
const {
  salutation: { value: salutation, errors: salutationErrors },
  salutationOptions,
  firstName: { value: firstName, errors: firstNameErrors },
  lastName: { value: lastName, errors: lastNameErrors },
  password: { value: password, errors: passwordErrors, meta: passwordMeta },
  possiblePasswordErrors,
  email: { value: email, errors: emailErrors },
  conditions: { value: conditions, errors: conditionsErrors },
  privacy: { value: privacy, errors: privacyErrors },
  newsletter: { value: newsletter, errors: newsletterErrors },
  handleSubmit,
  setInitialRegistrationData,
} = useRegistrationDataFields();

watch(
  initialValues,
  () => {
    if (initialValues.value) {
      setInitialRegistrationData(initialValues.value);
    }
  },
  { immediate: true },
);

const onSubmit = handleSubmit((fields: RegistrationFormData) =>
  emit("register", fields),
);
</script>

<template>
  <div class="registration-form">
    <h1 class="registration-form--title">
      {{ $t("registration.user.invited.title") }}
    </h1>
    <div class="registration-form--text">
      <p>
        {{ $t("registration.user.invited.info") }}
      </p>
      <p>
        {{ $t("registration.user.invited.info2") }}
      </p>
    </div>
    <div class="registration-view--form">
      <Form @submit="onSubmit">
        <template #default>
          <SelectField
            v-model="salutation"
            :options="salutationOptions"
            :errors="salutationErrors"
            :label="$t('shared.address.salutation')"
          />
          <TextField
            v-model="firstName"
            :errors="firstNameErrors"
            :label="$t('shared.address.firstName')"
            :maxlength="TEXTFIELD_MAX_LENGTH_DEFAULT"
            autocomplete="given-name"
          />
          <TextField
            v-model="lastName"
            :errors="lastNameErrors"
            :label="$t('shared.address.lastName')"
            :maxlength="TEXTFIELD_MAX_LENGTH_DEFAULT"
            autocomplete="family-name"
          />
          <TextField
            v-model="email"
            :errors="emailErrors"
            disabled
            :label="$t('shared.term.email.short')"
            :hint="$t('registration.user.invited.emailInfo')"
            autocomplete="email"
          />
          <PasswordField
            v-model="password"
            :errors="passwordErrors"
            :possible-errors="possiblePasswordErrors"
            show-rules
            :show-as-validated="passwordMeta.validated"
            :label="$t('shared.term.password.default')"
          />
          <div class="registration-form--permissions">
            <PermissionChecks
              v-model:conditions="conditions"
              v-model:privacy="privacy"
              v-model:newsletter="newsletter"
              :conditions-errors="conditionsErrors"
              :privacy-errors="privacyErrors"
              :newsletter-errors="newsletterErrors"
              :has-newsletter-checkbox="true"
            />
          </div>
        </template>
        <template #actions>
          <div class="registrations-form--actions">
            <Button
              type="submit"
              :caption="$t('registration.shared.action.register')"
              :is-loading="isLoading"
            />
          </div>
        </template>
      </Form>
    </div>
  </div>
</template>

<style scoped>
@import "RegistrationForm.css";
</style>
