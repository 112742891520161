<script lang="ts" setup>
import { computed, onBeforeUnmount, watch } from "vue";
import { fixBody } from "@/shared/helper";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    hasRightContent?: boolean;
  }>(),
  {
    hasRightContent: false,
  },
);

defineEmits<{
  (e: "overlay-clicked"): void;
}>();

watch(
  () => props.isVisible,
  (isVisible) => {
    fixBody(isVisible);
  },
);

onBeforeUnmount(() => fixBody(false));

const modifier = computed(() => ({
  "has-right-content": props.hasRightContent,
}));
</script>

<template>
  <teleport to="body">
    <div
      v-if="isVisible"
      class="overlay"
      :class="modifier"
    >
      <!--Eslint wants us to add a keydown event for accesibility reasons. This does not
    make sense here however, as this is a strictly visual feature.-->
      <!-- eslint-disable vuejs-accessibility/click-events-have-key-events, vuejs-accessibility/no-static-element-interactions -->
      <div
        class="overlay--backdrop"
        data-testid="overlay-backdrop"
        @click.prevent="$emit('overlay-clicked')"
      ></div>
      <!-- eslint-enable -->
      <slot></slot>
    </div>
  </teleport>
</template>

<style scoped>
@import "Overlay.css";
</style>
