import { ref } from "vue";
import type { InitialRegistrationData } from "@/app/registration-app/user/invited-registration/@types";
import { getRegistrationData } from "@/app/registration-app/user/invited-registration/services";
import { useI18n } from "vue-i18n";

const scopeByErrorKey: Record<string, string | undefined> = {
  NO_VALID_PROVISIONING_KEY: "registration.user.invited.error.invalidLink",
  USER_ALREADY_REGISTERED: "registration.user.invited.error.alreadyRegistered",
  GENERAL_ERROR: "shared.error.page.generalError",
};

export const useGetRegistrationData = (provisioningKey: string) => {
  const isFetchingRegistrationData = ref(false);
  const hasError = ref(false);
  const errorTitle = ref("");
  const errorText = ref("");
  const initialValues = ref<InitialRegistrationData | undefined>(undefined);
  const { t } = useI18n();

  const onFetchingRegistrationData = async () => {
    try {
      isFetchingRegistrationData.value = true;
      const registrationData = await getRegistrationData(provisioningKey);
      if (registrationData) {
        initialValues.value = {
          email: registrationData.email,
        };
        if (registrationData.salutation)
          initialValues.value.salutation = registrationData.salutation;
        if (registrationData.firstName)
          initialValues.value.firstName = registrationData.firstName;
        if (registrationData.lastName)
          initialValues.value.lastName = registrationData.lastName;
      }
      errorTitle.value = "";
      errorText.value = "";
      hasError.value = false;
    } catch (error) {
      const key = (error as string) ?? "GENERAL_ERROR";
      const scope = scopeByErrorKey[key] ?? scopeByErrorKey.GENERAL_ERROR;
      errorTitle.value = t(`${scope}.title`);
      errorText.value = t(`${scope}.text`);
      hasError.value = true;
    } finally {
      isFetchingRegistrationData.value = false;
    }
  };

  return {
    isFetchingRegistrationData,
    hasError,
    errorTitle,
    errorText,
    onFetchingRegistrationData,
    initialValues,
  };
};
