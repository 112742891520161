import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useField, useForm } from "vee-validate";
import {
  object as yupObject,
  string as yupString,
  boolean as yupBoolean,
  mixed,
} from "yup";
import {
  RegistrationSalutationInput,
  type RegistrationDataInput,
} from "@/shared/generated";
import { CustomerType } from "@/shared/@types";
import { useRegistrationSalutationField } from "@/shared/composables";
import {
  TEXTFIELD_MAX_LENGTH_DEFAULT,
  TEXTFIELD_MAX_LENGTH_LONG,
} from "@/shared/helper";

export interface RegistrationFormData extends RegistrationDataInput {
  privacy: boolean;
  condition: boolean;
  newsletter: boolean;
}

export const useRegistrationFields = () => {
  const { t } = useI18n();

  const possiblePasswordErrors = [
    t("shared.form.validation.min", { count: 10 }),
  ];

  const validationSchema = computed(() => {
    return yupObject({
      registrationType: mixed<CustomerType>()
        .oneOf(Object.values(CustomerType))
        .required(t("shared.form.validation.required")),
      companyName: yupString().when(
        "registrationType",
        ([registrationType], schema) =>
          registrationType === CustomerType.Company
            ? schema
                .trim()
                .required(t("shared.form.validation.required"))
                .max(
                  TEXTFIELD_MAX_LENGTH_LONG,
                  t("shared.form.validation.max", {
                    count: TEXTFIELD_MAX_LENGTH_LONG,
                  }),
                )
            : schema,
      ),
      salutation: mixed()
        .oneOf(Object.values(RegistrationSalutationInput))
        .required(t("shared.form.validation.required")),
      firstName: yupString()
        .trim()
        .required(t("shared.form.validation.required"))
        .max(
          TEXTFIELD_MAX_LENGTH_DEFAULT,
          t("shared.form.validation.max", {
            count: TEXTFIELD_MAX_LENGTH_DEFAULT,
          }),
        ),
      lastName: yupString()
        .trim()
        .required(t("shared.form.validation.required"))
        .max(
          TEXTFIELD_MAX_LENGTH_DEFAULT,
          t("shared.form.validation.max", {
            count: TEXTFIELD_MAX_LENGTH_DEFAULT,
          }),
        ),
      email: yupString()
        .trim()
        .email(t("shared.form.validation.email"))
        .required(t("shared.form.validation.required")),
      password: yupString()
        .min(10, possiblePasswordErrors[0])
        .required(possiblePasswordErrors[0]),
      conditions: yupBoolean()
        .required(t("shared.form.validation.checked"))
        .oneOf([true], t("shared.form.validation.checked")),
      privacy: yupBoolean()
        .required(t("shared.form.validation.checked"))
        .oneOf([true], t("shared.form.validation.checked")),
      newsletter: yupBoolean(),
      captcha: yupString().required(t("shared.form.validation.captcha")),
    });
  });

  const { handleSubmit, resetForm } = useForm<RegistrationFormData>({
    validationSchema,
  });

  const registrationTypeOptions = [
    {
      key: CustomerType.Private,
      caption: t("shared.address.privatePerson"),
    },
    {
      key: CustomerType.Company,
      caption: t("shared.address.company"),
    },
  ];
  const registrationType = useField<CustomerType>(
    "registrationType",
    undefined,
    {
      initialValue: CustomerType.Private,
    },
  );

  const companyName = useField<string>("companyName");

  const { salutation, salutationOptions } = useRegistrationSalutationField({
    required: false,
  });
  const firstName = useField<string>("firstName", undefined);
  const lastName = useField<string>("lastName", undefined);
  const email = useField<string>("email", undefined);
  const password = useField<string>("password", undefined);
  const conditions = useField<boolean>("conditions", undefined, {
    initialValue: false,
  });
  const privacy = useField<boolean>("privacy", undefined, {
    initialValue: false,
  });
  const newsletter = useField<boolean>("newsletter", undefined, {
    initialValue: false,
  });
  const captcha = useField<string>("captcha", undefined);

  return {
    handleSubmit,
    resetForm,
    registrationType,
    registrationTypeOptions,
    companyName,
    salutation,
    salutationOptions,
    firstName,
    lastName,
    email,
    password,
    possiblePasswordErrors,
    conditions,
    privacy,
    newsletter,
    captcha,
  };
};
