import { AppRouteNames } from "@/app/shared/@types";
import { ThgQuoteDeadlineStatus } from "@/shared/@types";
import { getThgQuoteDeadlineStatus } from "@/shared/helper";
import { useAccountStore } from "@/shared/stores";

const RegistrationView = () =>
  import(
    "@/app/thg-quote/unauthenticated-vehicle-registration/VehicleRegistrationView/VehicleRegistrationView.vue"
  );
const VehicleRegistrationView = () =>
  import(
    "@/app/thg-quote/vehicle-registration/VehicleRegistrationView/VehicleRegistrationView.vue"
  );
const VehicleApplicationsView = () =>
  import(
    "@/app/thg-quote/vehicle-applications/VehicleApplicationsView/VehicleApplicationsView.vue"
  );
const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");

export const routes = [
  {
    path: "",
    redirect: { name: AppRouteNames.ThgQuoteVehicleApplications },
  },
  {
    path: AppRouteNames.ThgQuoteVehicleApplications,
    name: AppRouteNames.ThgQuoteVehicleApplications,
    component: VehicleApplicationsView,
  },
  {
    path: "registration",
    name: AppRouteNames.ThgQuoteUnauthenticatedVehicleRegistration,
    component: RegistrationView,
    // Redirect to vehicle application overview if user is logged in
    beforeEnter: () => {
      const accountStore = useAccountStore();
      if (accountStore.userIsAuthenticated)
        return {
          name: AppRouteNames.ThgQuoteVehicleApplications,
        };

      return true;
    },
  },
  {
    path: "vehicle-registration",
    name: AppRouteNames.ThgQuoteVehicleRegistration,
    component: VehicleRegistrationView,
    beforeEnter: () => {
      const isRegistrationClosed =
        getThgQuoteDeadlineStatus() === ThgQuoteDeadlineStatus.closed;
      if (isRegistrationClosed)
        return {
          name: AppRouteNames.ThgQuoteVehicleApplications,
        };

      return true;
    },
  },
  {
    path: `:pathMatch(.*)*`,
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.ThgQuoteOverview,
    },
  },
];
