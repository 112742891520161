import { createClient } from "@/shared/urql/urql";
import {
  RegisterFleetAtHomeUserDocument,
  type FleetAtHomeRegistrationDataInput,
} from "@/shared/generated/graphql";
import { executeMutation } from "@/shared/helper";

export const registerFleetAtHomeUser = async (
  registrationData: FleetAtHomeRegistrationDataInput,
) => {
  const urqlClient = createClient();

  const error = await executeMutation(
    (data) => data?.registerFleetAtHomeUser,
    () =>
      urqlClient
        .mutation(RegisterFleetAtHomeUserDocument, {
          registrationData,
        })
        .toPromise(),
  );
  if (error) throw error;
};
