import { AppIdentifier } from "../@types";

export const getAppByPath = (route: string): AppIdentifier | undefined => {
  const [appId] = route.slice(1).split("/");

  switch (appId) {
    case "dashboard":
      return AppIdentifier.Dashboard;
    case "account":
      return AppIdentifier.Account;
    case "westfalen-id-account-management":
      return AppIdentifier.WestfalenIdAccountManagement;
    case "fbs-hub":
      return AppIdentifier.FbsHub;
    case "fleet-at-home":
      return AppIdentifier.FleetAtHome;
    case "fleet-at-home-management":
      return AppIdentifier.FleetAtHomeManagement;
    case "login":
      return AppIdentifier.Login;
    case "registration":
      return AppIdentifier.Registration;
    case "wcc-management":
      return AppIdentifier.WccManagement;
    case "wsc-management":
      return AppIdentifier.WscManagement;
    case "thg-quote":
      return AppIdentifier.ThgQuote;
    case "finish-registration":
      return AppIdentifier.FinishRegistration;
    default:
      return undefined;
  }
};
