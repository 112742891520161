import userManager from "./oidcConfig";
import { jwtDecode } from "jwt-decode";
import { DEV_JWT_KEY, isDevelopment } from "@/shared/helper";

export const login = async () => {
  await userManager.signinRedirect();
};

export const logout = () => {
  userManager.signoutRedirect();
};

const getUserWithRefreshedAccessToken = async () => {
  try {
    return await userManager.signinSilent();
  } catch {
    userManager.removeUser();
    window.location.href = "/login";
  }
};

export const getUser = async () => {
  const user = await userManager.getUser();
  if (!user) return null;

  const decodedAccessToken = jwtDecode(user.access_token);
  if (!decodedAccessToken.exp) return user;

  const expiresAt = new Date(decodedAccessToken.exp * 1000);
  const now = new Date(Date.now());

  if (expiresAt < now) return await getUserWithRefreshedAccessToken();

  return user;
};

export const handleCallback = async () => {
  try {
    await userManager.signinRedirectCallback();
  } catch (error) {
    return error;
  }
};

export const getJwt = async () => {
  const user = await getUser();

  // There is no user stored through the oidc-client-ts library in development mode, so we use the stored test user token from the local storage
  if (isDevelopment && !user) {
    return localStorage.getItem(DEV_JWT_KEY);
  }

  return user?.access_token;
};
