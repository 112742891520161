<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import {
  FRIENDLY_CAPTCHA_SITEKEY,
  FRIENDLY_CAPTCHA_PUZZLE_ENDPOINT,
  isDevelopment,
} from "@/shared/helper";
import { WidgetInstance, localizations } from "friendly-challenge";
import { i18n } from "@/shared/i18n/i18n";

const props = defineProps<{
  modelValue?: string;
  sitekey?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const locale = i18n.t("shared.term.localeString").split("-")[0];
const widgetLocale = (
  locale in Object.keys(localizations) ? locale : "de"
) as keyof typeof localizations;

const widgetElement = ref<HTMLElement | null>(null);

const captchaResult = ref(props.modelValue || "");

const captchaResultComputed = computed({
  get: () => captchaResult.value,
  set: (value: string) => {
    captchaResult.value = value;
    emit("update:modelValue", value);
  },
});

const doneCallback = (solution: string) => {
  captchaResultComputed.value = solution;
};

const errorCallback = () => {
  captchaResultComputed.value = "ERROR";
};

onMounted(() => {
  // Skip captcha in development/test environment
  if (isDevelopment) {
    setTimeout(() => {
      captchaResultComputed.value = "test";
    }, 100);
    return;
  }
  if (!widgetElement.value) {
    return;
  }

  const widget = new WidgetInstance(widgetElement.value, {
    doneCallback,
    errorCallback,
    sitekey: props.sitekey ?? FRIENDLY_CAPTCHA_SITEKEY,
    puzzleEndpoint: FRIENDLY_CAPTCHA_PUZZLE_ENDPOINT,
    language: widgetLocale,
  });

  widget.start();
});
</script>

<template>
  <div
    ref="widgetElement"
    class="frc-captcha"
  ></div>
</template>
