<script lang="ts" setup>
import { ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: string;
    options: Array<{
      key: string;
      caption: string;
    }>;
    errors?: Array<string>;
  }>(),
  { errors: () => [] },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const model = ref(props.modelValue);
watch(model, (newModel) => emit("update:modelValue", newModel));
</script>

<template>
  <div
    class="radiobutton-group"
    role="radiogroup"
  >
    <div class="radiobutton-group--wrapper">
      <label
        v-for="option in options"
        :key="option.key"
        class="radiobutton-group--option"
        :data-checked="option.key === model"
      >
        <div class="radiobutton-group--input-wrapper">
          <input
            v-model="model"
            class="radiobutton-group--input"
            type="radio"
            :value="option.key"
          />
        </div>
        <span class="radiobutton-group--label">{{ option.caption }}</span>
      </label>
    </div>
    <span class="radiobutton-group--error-message">{{ errors[0] }}</span>
  </div>
</template>

<style scoped lang="css">
@import "RadiobuttonGroup.css";
</style>
