<template>
  <div class="loading-dots">
    <svg
      viewBox="0 0 200 50"
      preserveAspectRatio="xMinYMin meet"
      class="loading-dots--graphic"
    >
      <g class="loading-dots--circles">
        <circle
          cx="25"
          cy="25"
          r="25"
          class="loading-dots--dot"
        />
        <circle
          cx="100"
          cy="25"
          r="25"
          class="loading-dots--dot"
        />
        <circle
          cx="175"
          cy="25"
          r="25"
          class="loading-dots--dot"
        />
      </g>
    </svg>
  </div>
</template>

<style scoped>
@import "LoadingDots.css";
</style>
