<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import TextField from "../TextField/TextField.vue";
import Icon from "../Icon/Icon.vue";

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    label: string;
    errors?: Array<string>;
    possibleErrors?: Array<string>;
    showRules?: boolean;
    showAsValidated?: boolean;
    name?: string;
    autocomplete?: string;
  }>(),
  {
    errors: () => [],
    possibleErrors: () => [],
    showRules: false,
    showAsValidated: true,
  },
);

defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const { t } = useI18n();

const isPasswordVisible = ref(false);
const inputType = computed(() =>
  isPasswordVisible.value ? "text" : "password",
);
const buttonTitle = computed(() =>
  isPasswordVisible.value
    ? t("shared.form.field.password.hide")
    : t("shared.form.field.password.show"),
);

const unmatchedRules = computed(() => {
  if (props.possibleErrors.length === 0) {
    return props.errors;
  }
  return props.possibleErrors.filter((rule) => props.errors.includes(rule));
});

const hints = computed(() =>
  props.possibleErrors.filter(
    (rule) => !props.showAsValidated && !props.errors.includes(rule),
  ),
);

const matchedRules = computed(
  () =>
    unmatchedRules.value.length === 0 &&
    props.possibleErrors.filter(
      (rule) => props.showAsValidated && !props.errors.includes(rule),
    ),
);
</script>

<template>
  <TextField
    :model-value="modelValue"
    :type="inputType"
    :label="label"
    :name="name"
    :errors="errors"
    :autocomplete="autocomplete"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #append>
      <button
        type="button"
        class="password-field--button"
        :title="buttonTitle"
        @click="isPasswordVisible = !isPasswordVisible"
      >
        <Icon
          v-if="isPasswordVisible"
          icon="visibility"
        />
        <Icon
          v-else
          icon="visibilityOff"
        />
      </button>
    </template>
    <template #errorMessage="{ errorMessageId }">
      <div
        v-if="showRules"
        class="password-field--rules"
      >
        <span
          v-for="(rule, index) in unmatchedRules"
          :id="errorMessageId"
          :key="index"
          class="password-field--unmatched-rule"
        >
          {{ rule }}
        </span>
        <span
          v-for="(rule, index) in hints"
          :key="index"
          class="password-field--hint"
        >
          {{ rule }}
        </span>
        <span
          v-for="(rule, index) in matchedRules"
          :key="index"
          class="password-field--matched-rule"
        >
          <Icon
            icon="check"
            :size="20"
          />
          {{ rule }}
        </span>
      </div>
    </template>
  </TextField>
</template>

<style scoped>
@import "PasswordField.css";
</style>
