<script lang="ts" setup>
import { computed, useSlots } from "vue";

type RadioValue = HTMLInputElement["value"];
type RadioName = HTMLInputElement["name"];

const props = defineProps<{
  modelValue: RadioValue;
  value: RadioValue;
  name: RadioName;
  label: string;
  description?: string;
  isErrored?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: RadioValue): void;
}>();

const isChecked = computed(() => props.modelValue === props.value);

const updateInput = () => emit("update:modelValue", props.value);

const slots = useSlots();
const hasAdditionalText = computed(() => !!slots.additionalText);
</script>

<template>
  <label
    class="radio-card"
    :data-checked="isChecked"
  >
    <div class="radio-card--input-wrapper">
      <input
        :value="value"
        :checked="isChecked"
        :name="name"
        class="radio-card--input"
        type="radio"
        @change="updateInput"
      />
    </div>
    <span class="radio-card--label">
      {{ label }}
    </span>
    <div class="radio-card--content">
      <p class="radio-card--description">
        <span v-if="description">
          {{ description }}
        </span>
        <span
          v-if="hasAdditionalText"
          class="radio-card--additional-text"
        >
          <slot name="additionalText"></slot>
        </span>
      </p>
    </div>
  </label>
</template>

<style scoped>
@import "RadioCard";
</style>
