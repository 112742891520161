import { computed, type Ref } from "vue";

export enum StepperType {
  Increase,
  Decrease,
}

export const useNumericStepper = (
  currentCount: Ref<string>,
  min = 0,
  max = Infinity,
) => {
  const isMinimum = computed(() => Number(currentCount.value) <= min);
  const isMaximum = computed(() => Number(currentCount.value) >= max);

  let interval: number;

  const stop = () => {
    clearInterval(interval);
  };

  const start = (
    type: StepperType,
    mouseEvent: MouseEvent,
    callback: () => void,
  ) => {
    // Reduce counter-event to main mouse-click to prevent unexpected behaviour
    if (mouseEvent.button !== 0) return;
    if (type === StepperType.Increase) {
      if (!isMaximum.value) callback();
      interval = window.setInterval(
        () => (isMaximum.value ? stop() : callback()),
        200,
      );
    } else {
      if (!isMinimum.value) callback();
      interval = window.setInterval(
        () => (isMinimum.value ? stop() : callback()),
        200,
      );
    }
  };

  return {
    isMinimum,
    isMaximum,
    start,
    stop,
  };
};
