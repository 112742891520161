<script lang="ts" setup>
import { onMounted } from "vue";
import {
  Button,
  Captcha,
  Form,
  PasswordField,
  PermissionChecks,
  RadiobuttonGroup,
  SelectField,
  TextField,
} from "@/shared/components";
import {
  TEXTFIELD_MAX_LENGTH_DEFAULT,
  TEXTFIELD_MAX_LENGTH_LONG,
} from "@/shared/helper";
import { CustomerType } from "@/shared/@types";
import { type RegistrationFormData } from "@/app/registration-app/user/registration/@types";
import { useRegistrationFields } from "./useRegistrationFields.composable";

withDefaults(
  defineProps<{
    isLoading?: boolean;
  }>(),
  {
    isLoading: false,
  },
);

const emit = defineEmits<{
  (e: "register", fields: RegistrationFormData): void;
}>();

const {
  registrationTypeOptions,
  registrationType: { value: registrationType },
  companyName: { value: companyName, errors: companyNameErrors },
  salutationOptions,
  salutation: { value: salutation, errors: salutationErrors },
  firstName: { value: firstName, errors: firstNameErrors },
  lastName: { value: lastName, errors: lastNameErrors },
  email: { value: email, errors: emailErrors },
  password: { value: password, errors: passwordErrors, meta: passwordMeta },
  possiblePasswordErrors,
  conditions: { value: conditions, errors: conditionsErrors },
  privacy: { value: privacy, errors: privacyErrors },
  newsletter: { value: newsletter, errors: newsletterErrors },
  captcha: { value: captcha },
  handleSubmit,
  resetForm,
} = useRegistrationFields();

const onSubmit = handleSubmit((fields: RegistrationFormData) =>
  emit("register", fields),
);

onMounted(() => resetForm());
</script>

<template>
  <div class="registration-form">
    <h1 class="registration-form--title">
      {{ $t("registration.user.new.title") }}
    </h1>
    <p class="registration-form--text">
      {{ $t("registration.user.new.text") }}
    </p>
    <Form @submit="onSubmit">
      <template #default>
        <RadiobuttonGroup
          v-model="registrationType"
          :options="registrationTypeOptions"
        />
        <SelectField
          v-model="salutation"
          :options="salutationOptions"
          :errors="salutationErrors"
          :label="$t('shared.address.salutation')"
        />
        <TextField
          v-model="firstName"
          :errors="firstNameErrors"
          :label="$t('shared.address.firstName')"
          :maxlength="TEXTFIELD_MAX_LENGTH_DEFAULT"
          autocomplete="given-name"
        />
        <TextField
          v-model="lastName"
          :errors="lastNameErrors"
          :label="$t('shared.address.lastName')"
          :maxlength="TEXTFIELD_MAX_LENGTH_DEFAULT"
          autocomplete="family-name"
        />
        <template v-if="registrationType === CustomerType.Company">
          <TextField
            v-model="companyName"
            :errors="companyNameErrors"
            :label="$t('shared.address.companyName')"
            :maxlength="TEXTFIELD_MAX_LENGTH_LONG"
          />
        </template>
        <TextField
          v-model="email"
          :errors="emailErrors"
          :label="$t('shared.term.email.short')"
          autocomplete="email"
        />
        <PasswordField
          v-model="password"
          :errors="passwordErrors"
          :possible-errors="possiblePasswordErrors"
          show-rules
          :show-as-validated="passwordMeta.validated"
          :label="$t('shared.term.password.default')"
        />
        <div class="registration-form--permissions">
          <PermissionChecks
            v-model:conditions="conditions"
            v-model:privacy="privacy"
            v-model:newsletter="newsletter"
            :conditions-errors="conditionsErrors"
            :privacy-errors="privacyErrors"
            :newsletter-errors="newsletterErrors"
            :has-newsletter-checkbox="true"
          />
        </div>
        <Captcha v-model="captcha" />
      </template>
      <template #actions>
        <div class="registration-form--actions">
          <Button
            type="submit"
            :caption="$t('registration.shared.action.register')"
            :is-loading="isLoading"
          />
          <Button
            :caption="$t('shared.action.reset.default')"
            :is-loading="isLoading"
            variant="outlined-black"
            @click="resetForm"
          />
        </div>
      </template>
    </Form>
  </div>
</template>

<style scoped>
@import "RegistrationForm.css";
</style>
