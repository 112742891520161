import { i18n } from "@/shared/i18n/i18n";

export const companyLinks = [
  {
    caption: i18n.t("shared.corporate.footer.link.company.title"),
    path: i18n.t("shared.corporate.footer.link.company.path"),
  },
  {
    caption: i18n.t("shared.corporate.footer.link.jobs.title"),
    path: i18n.t("shared.corporate.footer.link.jobs.path"),
  },
  {
    caption: i18n.t("shared.corporate.footer.link.press.title"),
    path: i18n.t("shared.corporate.footer.link.press.path"),
  },
];

export const informationLinks = [
  {
    caption: i18n.t("shared.corporate.service.link.contact.title"),
    path: i18n.t("shared.corporate.service.link.contact.path"),
  },
  {
    caption: i18n.t("shared.corporate.service.link.imprint.title"),
    path: i18n.t("shared.corporate.service.link.imprint.path"),
  },
];

export const legalLinks = [
  {
    caption: i18n.t("shared.corporate.service.link.privacy.title.default"),
    path: i18n.t("shared.corporate.service.link.privacy.path"),
  },
  {
    caption: i18n.t("shared.corporate.service.link.personalData.title.default"),
    path: i18n.t("shared.corporate.service.link.personalData.path"),
  },
  {
    caption: i18n.t("shared.corporate.service.link.cancellation.title.default"),
    path: i18n.t("shared.corporate.service.link.cancellation.path"),
  },
  {
    caption: i18n.t("shared.corporate.footer.link.cookies.title"),
    name: "cookies",
  },
  {
    caption: i18n.t("shared.corporate.service.link.conditions.title"),
    path: i18n.t("shared.corporate.service.link.conditions.path"),
  },
];
