<script lang="ts" setup>
import { onMounted, toRefs, watch } from "vue";
import { RegistrationForm } from "@/app/registration-app/user/invited-registration/components";
import { useGetRegistrationData } from "./useGetRegistrationData.composable";
import { useRegisterUser } from "./useRegisterUser.composable";

interface ErrorText {
  title: string;
  text: string;
}

const props = defineProps<{
  provisioningKey: string;
}>();

const { provisioningKey } = toRefs(props);

const emit = defineEmits<{
  (e: "show-success"): void;
  (e: "show-error", value: ErrorText): void;
}>();

// --- Fetch Registration-Data ---
const {
  isFetchingRegistrationData,
  hasError,
  errorTitle,
  errorText,
  onFetchingRegistrationData,
  initialValues,
} = useGetRegistrationData(props.provisioningKey);

onMounted(() => onFetchingRegistrationData());

// --- Register Fleet@Home-User ---
const { isRegisteringUser, onRegisteringUser } = useRegisterUser(
  provisioningKey.value,
  () => {
    emit("show-success");
  },
);

// --- Error ---
watch(hasError, () => {
  if (hasError)
    emit("show-error", { title: errorTitle.value, text: errorText.value });
});
</script>

<template>
  <RegistrationForm
    v-if="!isFetchingRegistrationData"
    :initial-values="initialValues"
    :is-loading="isRegisteringUser"
    @register="onRegisteringUser"
  />
</template>
