import { useField } from "vee-validate";
import { string as yupString } from "yup";
import { useI18n } from "vue-i18n";
import { TEXTFIELD_MAX_LENGTH_DEFAULT } from "@/shared/helper";

export function useFirstNameField(initialValue?: string) {
  const { t } = useI18n();

  const firstName = useField<string>(
    "firstName",
    yupString()
      .trim()
      .required(t("shared.form.validation.required"))
      .max(
        TEXTFIELD_MAX_LENGTH_DEFAULT,
        t("shared.form.validation.max", {
          count: TEXTFIELD_MAX_LENGTH_DEFAULT,
        }),
      ),
    { initialValue: initialValue || "" },
  );

  return {
    firstName,
  };
}
