import { useField, useForm } from "vee-validate";
import { boolean as yupBoolean } from "yup";
import { useI18n } from "vue-i18n";
import {
  useEmailField,
  usePasswordField,
  useSalutationField,
  useFirstNameField,
  useLastNameField,
} from "@/shared/composables";
import type {
  RegistrationFormData,
  InitialRegistrationData,
} from "@/app/registration-app/user/invited-registration/@types";

export const useRegistrationDataFields = () => {
  const { t } = useI18n();

  const { handleSubmit } = useForm<RegistrationFormData>();

  const { salutation, salutationOptions } = useSalutationField({
    required: true,
  });
  const { firstName } = useFirstNameField();

  const { lastName } = useLastNameField();

  const { email } = useEmailField();

  const { password, possibleErrors: possiblePasswordErrors } =
    usePasswordField();

  const conditions = useField<boolean>(
    "conditions",
    yupBoolean()
      .required(t("shared.form.validation.checked"))
      .oneOf([true], t("shared.form.validation.checked")),
    { initialValue: false },
  );

  const privacy = useField<boolean>(
    "privacy",
    yupBoolean()
      .required(t("shared.form.validation.checked"))
      .oneOf([true], t("shared.form.validation.checked")),
    { initialValue: false },
  );

  const newsletter = useField<boolean>("newsletter", yupBoolean(), {
    initialValue: false,
  });

  const setInitialRegistrationData = (
    initialValues: InitialRegistrationData,
  ) => {
    email.value.value = initialValues.email;
    if (initialValues.salutation)
      salutation.value.value = initialValues.salutation;
    if (initialValues.firstName)
      firstName.value.value = initialValues.firstName;
    if (initialValues.lastName) lastName.value.value = initialValues.lastName;
  };

  return {
    salutation,
    salutationOptions,
    firstName,
    lastName,
    password,
    possiblePasswordErrors,
    email,
    conditions,
    privacy,
    newsletter,
    handleSubmit,
    setInitialRegistrationData,
  };
};
