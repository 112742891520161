import { type FieldContext, useField } from "vee-validate";
import { mixed } from "yup";
import { useI18n } from "vue-i18n";
import { RegistrationSalutationInput } from "../generated/graphql";

type ReturnType<T extends boolean> = {
  salutation: T extends true
    ? FieldContext<RegistrationSalutationInput>
    : FieldContext<RegistrationSalutationInput | undefined>;
  salutationOptions: Array<{
    key: RegistrationSalutationInput;
    value: string;
  }>;
};

function useRegistrationSalutationField(options: {
  required: false;
  fieldName?: string;
  initialValue?: RegistrationSalutationInput;
}): ReturnType<false>;
function useRegistrationSalutationField(options: {
  required: true;
  fieldName?: string;
  initialValue?: RegistrationSalutationInput;
}): ReturnType<true>;
function useRegistrationSalutationField(options: {
  required: boolean;
  fieldName?: string;
  initialValue?: RegistrationSalutationInput;
}): ReturnType<true> | ReturnType<false>;
function useRegistrationSalutationField(options: {
  required: boolean;
  fieldName?: string;
  initialValue?: RegistrationSalutationInput;
}): ReturnType<true> | ReturnType<false> {
  const { t } = useI18n();

  const salutationRules = options.required
    ? mixed<RegistrationSalutationInput>()
        .oneOf(Object.values(RegistrationSalutationInput))
        .required(t("shared.form.validation.required"))
    : mixed<RegistrationSalutationInput>().oneOf(
        Object.values(RegistrationSalutationInput),
      );

  const { initialValue } = options;
  const salutation = useField<RegistrationSalutationInput | undefined>(
    options.fieldName ? options.fieldName : "salutation",
    salutationRules,
    {
      initialValue,
    },
  );

  const salutationOptions = [
    {
      key: RegistrationSalutationInput.Mrs,
      value: t("shared.address.salutations.mrs"),
    },
    {
      key: RegistrationSalutationInput.Mr,
      value: t("shared.address.salutations.mr"),
    },
    {
      key: RegistrationSalutationInput.NonBinary,
      value: t("shared.address.salutations.non_binary"),
    },
  ];

  return {
    salutation,
    salutationOptions,
  };
}

export { useRegistrationSalutationField };
