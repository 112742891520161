<script setup lang="ts">
import { ref } from "vue";
import { ColumnLayout, ColumnLayoutBox } from "@/shared/components";
import {
  ExistingRegistrationForm,
  SuccessfulRegistrationNote,
  HelpModal,
} from "@/app/registration-app/user/existing-registration/components";
import { useRegisterExistingUser } from "./useRegisterExistingUser.composable";

const isHelpModalVisible = ref(false);

// --- Register User ---
const { isRegisteringExistingUser, onRegisteringExistingUser, caseNumber } =
  useRegisterExistingUser();
</script>

<template>
  <HelpModal
    :is-visible="isHelpModalVisible"
    @cancel="isHelpModalVisible = false"
  />
  <ColumnLayout>
    <template #column1>
      <ColumnLayoutBox>
        <SuccessfulRegistrationNote
          v-if="caseNumber && !isRegisteringExistingUser"
          :case-number="caseNumber"
        />
        <ExistingRegistrationForm
          v-else
          :is-loading="isRegisteringExistingUser"
          @register="onRegisteringExistingUser"
          @open-help-modal="isHelpModalVisible = true"
        />
      </ColumnLayoutBox>
    </template>
  </ColumnLayout>
</template>
