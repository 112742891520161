import { AppIdentifier } from "@/shared/@types";
import { routes as accountRoutes } from "./account-app/routes";
import { routes as dashboardRoutes } from "./dashboard/routes";
import { routes as fbsHubRoutes } from "./fbs-hub-app/routes";
import { routes as fleetAtHomeRoutes } from "./fleet-at-home-app/routes";
import { routes as fleetAtHomeManagementRoutes } from "./fleet-at-home-management-app/routes";
import { routes as registrationRoutes } from "./registration-app/routes";
import { routes as thgQuoteRoutes } from "./thg-quote/routes";
import { routes as wccManagementRoutes } from "./wcc-management-app/routes";
import { routes as wscManagementRoutes } from "./wsc-management-app/routes";
import { routes as westfalenIdAccountManagementRoutes } from "./wid-account-management-app/routes";
import { routes as finishRegistrationRoutes } from "./finish-registration-app/routes";
import { AppRouteNames } from "./shared/@types";
import { isDevelopment } from "@/shared/helper";
import { login } from "./AuthService";

const AccountApp = () => import("./account-app/AccountApp.vue");
const DevLoginApp = () => import("./dev-login/DevLoginApp.vue");
const LoginCallbackApp = () => import("./login-callback/LoginCallBackApp.vue");
const DashboardApp = () => import("./dashboard/DashboardApp.vue");
const FbsHubApp = () => import("./fbs-hub-app/FbsHubApp.vue");
const FleetAtHomeApp = () => import("./fleet-at-home-app/FleetAtHomeApp.vue");
const FleetAtHomeManagementApp = () =>
  import("./fleet-at-home-management-app/FleetAtHomeManagementApp.vue");
const RegistrationApp = () => import("./registration-app/RegistrationApp.vue");
const ThgQuoteApp = () => import("./thg-quote/ThgQuoteApp.vue");
const WccManagementApp = () =>
  import("./wcc-management-app/WccManagementApp.vue");
const WscManagementApp = () =>
  import("./wsc-management-app/WscManagementApp.vue");
const WestfalenIdAccountManagementApp = () =>
  import("./wid-account-management-app/WidAccountManagementApp.vue");
const FinishRegistrationApp = () =>
  import("./finish-registration-app/FinishRegistrationApp.vue");
const GeneralErrorApp = () => import("./general-error/GeneralErrorApp.vue");
const MaintenanceApp = () => import("./maintenance/MaintenanceApp.vue");

export const routes = [
  {
    path: `/${AppIdentifier.Account}`,
    component: AccountApp,
    children: [...accountRoutes],
  },
  {
    path: `/${AppIdentifier.Dashboard}`,
    component: DashboardApp,
    children: [...dashboardRoutes],
  },
  {
    path: `/${AppIdentifier.FbsHub}`,
    component: FbsHubApp,
    children: [...fbsHubRoutes],
  },
  {
    path: `/${AppIdentifier.FleetAtHome}`,
    component: FleetAtHomeApp,
    children: [...fleetAtHomeRoutes],
  },
  {
    path: `/${AppIdentifier.FleetAtHomeManagement}`,
    component: FleetAtHomeManagementApp,
    children: [...fleetAtHomeManagementRoutes],
  },
  {
    path: `/${AppIdentifier.Registration}`,
    component: RegistrationApp,
    children: [...registrationRoutes],
  },
  {
    path: `/${AppIdentifier.ThgQuote}`,
    component: ThgQuoteApp,
    children: [...thgQuoteRoutes],
  },
  {
    path: `/${AppIdentifier.WccManagement}`,
    component: WccManagementApp,
    children: [...wccManagementRoutes],
  },
  {
    path: `/${AppIdentifier.WscManagement}`,
    component: WscManagementApp,
    children: [...wscManagementRoutes],
  },
  {
    path: `/${AppIdentifier.WestfalenIdAccountManagement}`,
    component: WestfalenIdAccountManagementApp,
    children: [...westfalenIdAccountManagementRoutes],
  },
  {
    path: "/login",
    name: AppRouteNames.Login,
    component: DevLoginApp,
    beforeEnter: async () => {
      if (!isDevelopment) await login();
    },
  },
  {
    path: "/auth/callback",
    name: AppRouteNames.LoginCallback,
    component: LoginCallbackApp,
  },
  {
    path: "/finish-registration",
    component: FinishRegistrationApp,
    children: [...finishRegistrationRoutes],
  },
  {
    path: "/general-error",
    name: AppRouteNames.GeneralError,
    component: GeneralErrorApp,
  },
  {
    path: "/maintenance",
    name: AppRouteNames.Maintenance,
    component: MaintenanceApp,
  },
];
