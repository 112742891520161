<script lang="ts" setup>
import { computed, toRefs } from "vue";
import { getUniqueId } from "@/shared/helper";
import type { IconName } from "@/shared/@types";
import Icon from "../Icon/Icon.vue";

// IMPORTANT: This component may only be used in combination with the elements input, select and textarea.
const props = withDefaults(
  defineProps<{
    label: string;
    id: string;
    size?: "s" | "m" | "l";
    errors?: Array<string>;
    prependIcon?: IconName;
    isLabelHidden?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    hint?: string;
    hasLinkedHint?: boolean;
    hideErrorAppendIcon?: boolean;
  }>(),
  {
    size: "m",
    errors: () => [],
    isLabelHidden: false,
    isRequired: false,
    isDisabled: false,
    hideErrorAppendIcon: false,
  },
);

const { id: inputFieldId } = toRefs(props);

defineEmits<{
  (e: "openHint"): void;
}>();

const errorMessageId = getUniqueId("input-group-error");
const inputBindings = computed(() => {
  return {
    "aria-invalid": props.errors.length > 0 || undefined,
    "aria-errormessage": props.errors.length > 0 ? errorMessageId : undefined,
  };
});
</script>

<template>
  <div
    class="input-group"
    :data-size="size"
    :data-errored="!!errors[0]"
    :data-label-hidden="isLabelHidden"
    :data-required="isRequired"
    :data-disabled="isDisabled"
    :data-has-append="!!$slots.append"
    :data-has-prepend="!!prependIcon"
  >
    <div class="input-group--label-box">
      <label
        class="input-group--label"
        :for="inputFieldId"
      >
        {{ label }}
      </label>
      <slot name="label-append"></slot>
    </div>
    <div class="input-group--field">
      <label
        v-if="prependIcon"
        class="input-group--prepend"
        :for="inputFieldId"
      >
        <Icon :icon="prependIcon" />
      </label>
      <slot :input-bindings="inputBindings"></slot>
      <label
        v-if="!!$slots.append"
        class="input-group--append"
        :for="inputFieldId"
      >
        <slot name="append"></slot>
      </label>
      <div
        v-else-if="!!errors[0] && !hideErrorAppendIcon"
        class="input-group--append"
      >
        <div class="input-group--append-error-icon">
          <Icon icon="errorCircleRounded" />
        </div>
      </div>
    </div>
    <template v-if="hint">
      <button
        v-if="hasLinkedHint"
        type="button"
        class="input-group--hint is-linked"
        @click="$emit('openHint')"
      >
        {{ hint }}
      </button>
      <span
        v-else
        class="input-group--hint"
      >
        {{ hint }}
      </span>
    </template>
    <slot
      name="errorMessage"
      :error-message-id="errorMessageId"
    >
      <span
        v-if="!!errors[0]"
        :id="errorMessageId"
        class="input-group--error-message"
      >
        {{ errors[0] }}
      </span>
    </slot>
  </div>
</template>

<style scoped>
@import "InputGroup.css";
</style>
