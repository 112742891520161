import { type FieldContext, useField } from "vee-validate";
import { mixed } from "yup";
import { useI18n } from "vue-i18n";
import { Salutation } from "../generated/graphql";
import { ValidSalutation } from "../@types";

type ReturnType<T extends boolean> = {
  salutation: T extends true
    ? FieldContext<Salutation>
    : FieldContext<Salutation | undefined>;
  salutationOptions: Array<{
    key: Salutation;
    value: string;
  }>;
};

function useSalutationField(options: {
  required: false;
  fieldName?: string;
  initialValue?: Salutation;
}): ReturnType<false>;
function useSalutationField(options: {
  required: true;
  fieldName?: string;
  initialValue?: Salutation;
}): ReturnType<true>;
function useSalutationField(options: {
  required: boolean;
  fieldName?: string;
  initialValue?: Salutation;
}): ReturnType<true> | ReturnType<false>;
function useSalutationField(options: {
  required: boolean;
  fieldName?: string;
  initialValue?: Salutation;
}): ReturnType<true> | ReturnType<false> {
  const { t } = useI18n();

  const salutationRules = options.required
    ? mixed<Salutation>()
        .oneOf(Object.values(Salutation))
        .required(t("shared.form.validation.required"))
    : mixed<Salutation>().oneOf(Object.values(Salutation));

  const { initialValue } = options;
  const salutation = useField<Salutation | undefined>(
    options.fieldName ? options.fieldName : "salutation",
    salutationRules,
    {
      initialValue:
        initialValue &&
        (Object.values(ValidSalutation) as Array<Salutation>).includes(
          initialValue,
        )
          ? initialValue
          : undefined,
    },
  );

  const salutationOptions = [
    { key: Salutation.Mrs, value: t("shared.address.salutations.mrs") },
    { key: Salutation.Mr, value: t("shared.address.salutations.mr") },
    {
      key: Salutation.MarriedCouple,
      value: t("shared.address.salutations.married_couple"),
    },
    { key: Salutation.Company, value: t("shared.address.salutations.company") },
    {
      key: Salutation.MrAndMrs,
      value: t("shared.address.salutations.mr_and_mrs"),
    },
    {
      key: Salutation.MrsAndMr,
      value: t("shared.address.salutations.mrs_and_mr"),
    },
    {
      key: Salutation.NonBinary,
      value: t("shared.address.salutations.non_binary"),
    },
  ];

  return {
    salutation,
    salutationOptions,
  };
}

export { useSalutationField };
