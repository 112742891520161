import base from "./base.json";
import documents from "./documents.json";
import gasContainers from "./gas-containers.json";
import checkout from "./checkout.json";
import meterReport from "./meter-report.json";
import monthlyPayment from "./monthly-payment.json";
import orders from "./orders.json";
import priceCockpit from "./price-cockpit.json";
import start from "./start.json";
import westfalengasBio from "./westfalengas-bio.json";

const fbsHub = {
  ...base,
  ...checkout,
  ...documents,
  ...gasContainers,
  ...meterReport,
  ...monthlyPayment,
  ...orders,
  ...priceCockpit,
  ...start,
  ...westfalengasBio,
};

export default fbsHub;
