<script lang="ts" setup>
import { computed, defineAsyncComponent } from "vue";
import { type IconName, IconEnum } from "@/shared/@types";

const props = withDefaults(
  defineProps<{
    icon: IconName;
    size?: 18 | 20 | 24 | 32 | 40 | 64;
  }>(),
  {
    size: 24,
  },
);

const component = computed(() => {
  if (props.icon) {
    return defineAsyncComponent(
      () => import(`./icons/${IconEnum[props.icon]}.svg`),
    );
  }
  return undefined;
});
</script>

<template>
  <component
    :is="component"
    :width="size"
    :height="size"
    class="icon"
  />
</template>

<style scoped>
@import "Icon.css";
</style>
