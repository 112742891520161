import { AppRouteNames } from "@/app/shared/@types";

const StartView = () =>
  import("@/app/fbs-hub-app/start/StartView/StartView.vue");
const CheckoutView = () =>
  import("@/app/fbs-hub-app/checkout/CheckoutView/CheckoutView.vue");
const GasContainersView = () =>
  import(
    "@/app/fbs-hub-app/gas-containers/GasContainersView/GasContainersView.vue"
  );
const WestfalengasBioView = () =>
  import(
    "@/app/fbs-hub-app/westfalengas-bio/WestfalengasBioView/WestfalengasBioView.vue"
  );
const PriceCockpitView = () =>
  import(
    "@/app/fbs-hub-app/price-cockpit/PriceCockpitView/PriceCockpitView.vue"
  );
const MeterReportView = () =>
  import("@/app/fbs-hub-app/meter-report/MeterReportView/MeterReportView.vue");
const MonthlyPaymentView = () =>
  import(
    "@/app/fbs-hub-app/monthly-payment/MonthlyPaymentView/MonthlyPaymentView.vue"
  );
const OrdersView = () =>
  import("@/app/fbs-hub-app/orders/OrdersView/OrdersView.vue");
const DocumentsView = () =>
  import("@/app/fbs-hub-app/documents/DocumentsView/DocumentsView.vue");
const PageNotFoundView = () =>
  import("@/shared/views/PageNotFoundView/PageNotFoundView.vue");

export const routes = [
  {
    path: "",
    redirect: { name: AppRouteNames.FbsHubStart },
  },
  {
    path: "start",
    name: AppRouteNames.FbsHubStart,
    meta: {
      titleKey: "fbsHub.start.title",
    },
    component: StartView,
  },
  {
    path: "checkout",
    name: AppRouteNames.FbsHubCheckout,
    meta: {
      titleKey: "fbsHub.checkout.title",
    },
    component: CheckoutView,
  },
  {
    path: "gas-containers",
    name: AppRouteNames.FbsHubGasContainers,
    meta: {
      titleKey: "fbsHub.gasContainers.title",
    },
    component: GasContainersView,
  },
  {
    path: "westfalengas-bio",
    name: AppRouteNames.FbsHubWestfalengasBio,
    meta: {
      titleKey: "fbsHub.westfalengasBio.title",
    },
    component: WestfalengasBioView,
  },
  {
    path: "price-cockpit",
    name: AppRouteNames.FbsHubPriceCockpit,
    meta: {
      titleKey: "fbsHub.priceCockpit.title",
    },
    component: PriceCockpitView,
  },
  {
    path: "meter-report",
    name: AppRouteNames.FbsHubMeterReport,
    meta: {
      titleKey: "fbsHub.meterReport.title",
    },
    component: MeterReportView,
  },
  {
    path: "monthly-payment",
    name: AppRouteNames.FbsHubMonthlyPayment,
    meta: {
      titleKey: "fbsHub.monthlyPayment.title",
    },
    component: MonthlyPaymentView,
  },
  {
    path: "orders",
    name: AppRouteNames.FbsHubOrders,
    meta: {
      titleKey: "fbsHub.orders.title",
    },
    component: OrdersView,
  },
  {
    path: "documents",
    name: AppRouteNames.FbsHubDocuments,
    meta: {
      titleKey: "fbsHub.documents.title",
    },
    component: DocumentsView,
  },
  {
    path: ":pathMatch(.*)*",
    name: AppRouteNames.FbsHubNotFound,
    meta: {
      titleKey: "shared.error.page.notFoundError.title",
    },
    component: PageNotFoundView,
    props: {
      homeRouteName: AppRouteNames.FbsHubStart,
    },
  },
];
