<script lang="ts" setup>
import { onMounted } from "vue";
import {
  Button,
  Captcha,
  Form,
  PermissionChecks,
  SelectField,
  TextField,
  Icon,
} from "@/shared/components";
import { TEXTFIELD_MAX_LENGTH_DEFAULT } from "@/shared/helper";
import type { ExistingRegistrationFormData } from "@/app/registration-app/user/existing-registration/@types";
import { useExistingRegistrationFields } from "./useExistingRegistrationFields.composable";

withDefaults(
  defineProps<{
    isLoading?: boolean;
  }>(),
  {
    isLoading: false,
  },
);

const emit = defineEmits<{
  (e: "register", fields: ExistingRegistrationFormData): void;
  (e: "open-help-modal"): void;
}>();

const {
  salutationOptions,
  salutation: { value: salutation, errors: salutationErrors },
  firstName: { value: firstName, errors: firstNameErrors },
  lastName: { value: lastName, errors: lastNameErrors },
  email: { value: email, errors: emailErrors },
  customerId: { value: customerId, errors: customerIdErrors },
  conditions: { value: conditions, errors: conditionsErrors },
  privacy: { value: privacy, errors: privacyErrors },
  captcha: { value: captcha },
  handleSubmit,
  resetForm,
} = useExistingRegistrationFields();

const onSubmit = handleSubmit((fields: ExistingRegistrationFormData) =>
  emit("register", fields),
);

onMounted(() => resetForm());
</script>

<template>
  <div class="existing-registration-form">
    <h1 class="existing-registration-form--title">
      {{ $t("registration.user.existing.title") }}
    </h1>
    <p class="existing-registration-form--text">
      {{ $t("registration.user.existing.text") }}
    </p>
    <Form @submit="onSubmit">
      <template #default>
        <SelectField
          v-model="salutation"
          :options="salutationOptions"
          :errors="salutationErrors"
          :label="$t('shared.address.salutation')"
        />
        <TextField
          v-model="firstName"
          :errors="firstNameErrors"
          :label="$t('shared.address.firstName')"
          :maxlength="TEXTFIELD_MAX_LENGTH_DEFAULT"
          autocomplete="given-name"
        />
        <TextField
          v-model="lastName"
          :errors="lastNameErrors"
          :label="$t('shared.address.lastName')"
          :maxlength="TEXTFIELD_MAX_LENGTH_DEFAULT"
          autocomplete="family-name"
        />
        <TextField
          v-model="email"
          :errors="emailErrors"
          :label="$t('shared.term.email.short')"
          autocomplete="email"
        />
        <TextField
          v-model="customerId"
          :errors="customerIdErrors"
          :label="$t('registration.user.existing.customerId')"
          inputmode="numeric"
        >
          <template #append>
            <Icon
              class="existing-registration-form--customer-id-icon"
              icon="help"
              @click="$emit('open-help-modal')"
            />
          </template>
        </TextField>
        <div class="existing-registration-form--permissions">
          <PermissionChecks
            v-model:conditions="conditions"
            v-model:privacy="privacy"
            :conditions-errors="conditionsErrors"
            :privacy-errors="privacyErrors"
            :has-newsletter-checkbox="false"
          />
        </div>
        <Captcha v-model="captcha" />
      </template>
      <template #actions>
        <div class="existing-registration-form--actions">
          <Button
            type="submit"
            :caption="$t('registration.shared.action.register')"
            :is-loading="isLoading"
          />
          <Button
            :caption="$t('shared.action.reset.default')"
            :is-loading="isLoading"
            variant="outlined-black"
            @click="resetForm"
          />
        </div>
      </template>
    </Form>
  </div>
</template>

<style scoped>
@import "ExistingRegistrationForm.css";
</style>
