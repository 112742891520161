import { AppRouteNames } from "@/app/registration-app/shared/@types";
import RegistrationView from "@/app/registration-app/user/registration/RegistrationView/RegistrationView.vue";
import ExistingRegistrationView from "@/app/registration-app/user/existing-registration/ExistingRegistrationView/ExistingRegistrationView.vue";
import InvitedRegistrationView from "@/app/registration-app/user/invited-registration/InvitedRegistrationView/InvitedRegistrationView.vue";
import VerifyEmailView from "@/app/registration-app/user/verify-email/VerifyEmailView/VerifyEmailView.vue";
import type { RouteLocationNormalizedLoaded } from "vue-router";

export const routes = [
  {
    path: "",
    name: AppRouteNames.UserRegistration,
    component: RegistrationView,
  },
  {
    path: "existing-user",
    name: AppRouteNames.ExistingUserRegistration,
    component: ExistingRegistrationView,
  },
  {
    path: "confirm",
    name: AppRouteNames.InvitedUserRegistration,
    component: InvitedRegistrationView,
  },
  {
    path: "verify-email",
    name: AppRouteNames.VerifyEmail,
    component: VerifyEmailView,
    props: (route: RouteLocationNormalizedLoaded) => ({
      email: route.query.email,
      token: route.query.token,
    }),
  },
];
