import { type FieldContext, useField } from "vee-validate";
import { mixed } from "yup";
import { useI18n } from "vue-i18n";
import { ExistingRegistrationSalutationInput } from "../generated/graphql";

type ReturnType<T extends boolean> = {
  salutation: T extends true
    ? FieldContext<ExistingRegistrationSalutationInput>
    : FieldContext<ExistingRegistrationSalutationInput | undefined>;
  salutationOptions: Array<{
    key: ExistingRegistrationSalutationInput;
    value: string;
  }>;
};

function useExistingRegistrationSalutationField(options: {
  required: false;
  fieldName?: string;
  initialValue?: ExistingRegistrationSalutationInput;
}): ReturnType<false>;
function useExistingRegistrationSalutationField(options: {
  required: true;
  fieldName?: string;
  initialValue?: ExistingRegistrationSalutationInput;
}): ReturnType<true>;
function useExistingRegistrationSalutationField(options: {
  required: boolean;
  fieldName?: string;
  initialValue?: ExistingRegistrationSalutationInput;
}): ReturnType<true> | ReturnType<false>;
function useExistingRegistrationSalutationField(options: {
  required: boolean;
  fieldName?: string;
  initialValue?: ExistingRegistrationSalutationInput;
}): ReturnType<true> | ReturnType<false> {
  const { t } = useI18n();

  const salutationRules = options.required
    ? mixed<ExistingRegistrationSalutationInput>()
        .oneOf(Object.values(ExistingRegistrationSalutationInput))
        .required(t("shared.form.validation.required"))
    : mixed<ExistingRegistrationSalutationInput>().oneOf(
        Object.values(ExistingRegistrationSalutationInput),
      );

  const { initialValue } = options;
  const salutation = useField<ExistingRegistrationSalutationInput | undefined>(
    options.fieldName ? options.fieldName : "salutation",
    salutationRules,
    {
      initialValue,
    },
  );

  const salutationOptions = [
    {
      key: ExistingRegistrationSalutationInput.Mrs,
      value: t("shared.address.salutations.mrs"),
    },
    {
      key: ExistingRegistrationSalutationInput.Mr,
      value: t("shared.address.salutations.mr"),
    },
  ];

  return {
    salutation,
    salutationOptions,
  };
}

export { useExistingRegistrationSalutationField };
