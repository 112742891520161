import { ref } from "vue";
import { useNotifications } from "@/shared/composables";
import type { RegistrationFormData } from "@/app/registration-app/user/invited-registration/@types";
import { registerFleetAtHomeUser } from "@/app/registration-app/user/invited-registration/services";

export const useRegisterUser = (
  provisioningKey: string,
  onActionFinished: () => void,
) => {
  const { addErrorSnackbar } = useNotifications();

  const isRegisteringUser = ref(false);

  const onRegisteringUser = async (registrationData: RegistrationFormData) => {
    try {
      isRegisteringUser.value = true;
      await registerFleetAtHomeUser({
        provisioningKey,
        salutation: registrationData.salutation,
        firstName: registrationData.firstName,
        lastName: registrationData.lastName,
        password: registrationData.password,
        newsletterConsent: registrationData.newsletter,
      });
      onActionFinished();
    } catch (error) {
      addErrorSnackbar(error as string);
    } finally {
      isRegisteringUser.value = false;
    }
  };

  return {
    isRegisteringUser,
    onRegisteringUser,
  };
};
