<script lang="ts" setup>
import { getUniqueId } from "@/shared/helper";
import Icon from "../Icon/Icon.vue";
import InputGroup from "../InputGroup/InputGroup.vue";

withDefaults(
  defineProps<{
    modelValue?: string;
    options: Array<{ key: string; value: string; disabled?: boolean }>;
    label: string;
    errors?: Array<string>;
    disabled?: boolean;
  }>(),
  {
    errors: () => [],
  },
);
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const onChange = (event: Event) => {
  emit("update:modelValue", (event.target as HTMLSelectElement).value);
};

const inputId = getUniqueId("select-field");
</script>

<template>
  <InputGroup
    :id="inputId"
    :label="label"
    :errors="errors"
    :is-disabled="disabled"
  >
    <template #default="{ inputBindings }">
      <select
        :id="inputId"
        :value="modelValue"
        :disabled="disabled"
        v-bind="inputBindings"
        @change="onChange"
      >
        <option
          disabled
          value
          selected
        >
          {{ $t("shared.form.field.select.noValue") }}
        </option>
        <option
          v-for="option in options"
          :key="option.key"
          :value="option.key"
          :disabled="option.disabled"
        >
          {{ option.value }}
        </option>
      </select>
    </template>
    <template #append>
      <Icon icon="expandMore" />
    </template>
  </InputGroup>
</template>
