import { createClient } from "@/shared/urql/urql";
import {
  RegisterExistingCustomerDocument,
  type RegisterExistingCustomerInput,
} from "@/shared/generated/graphql";
import { handleCombinedError, handleErrors } from "@/shared/helper";

export const registerExistingUser = async (
  registrationData: RegisterExistingCustomerInput,
) => {
  const urqlClient = createClient();

  const { error, data } = await urqlClient
    .mutation(RegisterExistingCustomerDocument, { registrationData })
    .toPromise();
  const errors = data?.registerExistingCustomer?.errors;
  if (error) throw handleCombinedError(error);
  if (errors && errors.length > 0) throw handleErrors(errors);

  return data?.registerExistingCustomer?.caseNumber || undefined;
};
