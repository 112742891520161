export enum AppRouteNames {
  AccountChangeBankDetails = "account-change-bank-details",
  AccountChangeEmail = "account-change-email",
  AccountChangePassword = "account-change-password",
  AccountChangeProfile = "account-change-profile",
  AccountDeleteAccount = "account-delete-account",
  AccountNotFound = "account-not-found",
  AccountOverview = "account-overview",
  Dashboard = "dashboard",
  DashboardNotFound = "dashboard-not-found",
  ExistingUserRegistration = "existing-user-registration",
  FbsHubStart = "fbs-hub-start",
  FbsHubCheckout = "fbs-hub-checkout",
  FbsHubGasContainers = "fbs-hub-gas-containers",
  FbsHubWestfalengasBio = "fbs-hub-westfalengas-bio",
  FbsHubPriceCockpit = "fbs-hub-price-cockpit",
  FbsHubMeterReport = "fbs-hub-meter-report",
  FbsHubMonthlyPayment = "fbs-hub-monthly-payment",
  FbsHubOrders = "fbs-hub-orders",
  FbsHubDocuments = "fbs-hub-documents",
  FbsHubNotFound = "fbs-hub-not-found",
  FinishRegistration = "finish-registration",
  FinishRegistrationNotFound = "finish-registration-not-found",
  FleetAtHomeCharges = "fleet-at-home-charges",
  FleetAtHomeCredits = "fleet-at-home-credits",
  FleetAtHomeManagementNotFound = "fleet-at-home-management-not-found",
  FleetAtHomeManagementUsers = "fleet-at-home-management-users",
  FleetAtHomeNotFound = "fleet-at-home-not-found",
  FleetAtHomeShop = "fleet-at-home-shop",
  FleetAtHomeShopProduct = "fleet-at-home-shop-product",
  FleetAtHomeStations = "fleet-at-home-stations",
  GeneralError = "general-error",
  InvitedUserRegistration = "invited-user-registration",
  Login = "login",
  LoginCallback = "login-callback",
  Maintenance = "maintenance",
  NotFound = "not-found",
  ThgQuoteNotFound = "thg-quote-not-found",
  ThgQuoteOverview = "thg-quote-overview",
  ThgQuoteVehicleRegistration = "thg-quote-vehicle-registration",
  ThgQuoteUnauthenticatedVehicleRegistration = "thg-quote-registration",
  ThgQuoteVehicleApplications = "thg-quote-vehicle-applications",
  UserRegistration = "user-registration",
  VerifyEmail = "verify-email",
  WccCardsArchive = "wcc-management-cards-archive",
  WccCardsOverview = "wcc-management-cards-overview",
  WccCharges = "wcc-management-charges",
  WccInvoices = "wcc-management-invoices",
  WccNotFound = "wcc-management-not-found",
  WccOrders = "wcc-management-orders",
  WestfalenIdAccountManagementOverview = "westfalen-id-account-management-overview",
  WestfalenIdAccountManagementUsers = "westfalen-id-account-management-users",
  WestfalenIdAccountManagementServices = "westfalen-id-account-management-services",
  WestfalenIdAccountNotFound = "westfalen-id-account-not-found",
  WscCardsOverview = "wsc-management-cards-overview",
  WscInvoices = "wsc-management-invoices",
  WscNotFound = "wsc-management-not-found",
  WscTransactions = "wsc-management-transactions",
}
