<script lang="ts" setup>
import CheckboxField from "../CheckboxField/CheckboxField.vue";

defineProps<{
  conditions: boolean;
  privacy: boolean;
  newsletter?: boolean;
  conditionsErrors?: Array<string>;
  privacyErrors?: Array<string>;
  newsletterErrors?: Array<string>;
  hasNewsletterCheckbox: boolean;
}>();

const emit = defineEmits<{
  (e: "update:conditions", value: boolean): void;
  (e: "update:privacy", value: boolean): void;
  (e: "update:newsletter", value: boolean): void;
}>();

const updateConditions = (value: boolean) => emit("update:conditions", value);
const updatePrivacy = (value: boolean) => emit("update:privacy", value);
const updateNewsletter = (value: boolean) => emit("update:newsletter", value);
</script>

<template>
  <div class="permission-checks">
    <CheckboxField
      :model-value="conditions"
      :errors="conditionsErrors"
      @update:model-value="updateConditions"
    >
      <i18n-t
        keypath="shared.corporate.permissionChecks.conditions.text"
        tag="div"
      >
        <template #conditionsLink>
          <a
            :href="$t('shared.corporate.service.link.conditions.path')"
            target="_blank"
            rel="noopener noreferrer"
            class="permission-checks--link"
          >
            {{
              $t("shared.corporate.permissionChecks.conditions.conditionsLink")
            }}
          </a>
        </template>
      </i18n-t>
    </CheckboxField>
    <CheckboxField
      :model-value="privacy"
      :errors="privacyErrors"
      @update:model-value="updatePrivacy"
    >
      <i18n-t
        keypath="shared.corporate.permissionChecks.privacy.text"
        tag="div"
      >
        <template #privacyLink>
          <a
            :href="$t('shared.corporate.service.link.privacy.path')"
            target="_blank"
            rel="noopener noreferrer"
            class="permission-checks--link"
            >{{
              $t("shared.corporate.permissionChecks.privacy.privacyLink")
            }}</a
          >
        </template>
        <template #personalDataLink>
          <a
            :href="$t('shared.corporate.service.link.personalData.path')"
            target="_blank"
            rel="noopener noreferrer"
            class="permission-checks--link"
          >
            {{
              $t("shared.corporate.permissionChecks.privacy.personalDataLink")
            }}
          </a>
        </template>
      </i18n-t>
    </CheckboxField>
    <CheckboxField
      v-if="hasNewsletterCheckbox"
      :model-value="newsletter || false"
      :errors="newsletterErrors"
      @update:model-value="updateNewsletter"
    >
      <i18n-t
        keypath="shared.corporate.permissionChecks.newsletter.text"
        tag="div"
      >
        <template #privacyLink>
          <a
            :href="$t('shared.corporate.service.link.privacy.path')"
            target="_blank"
            rel="noopener noreferrer"
            class="permission-checks--link"
            >{{
              $t("shared.corporate.permissionChecks.newsletter.privacyLink")
            }}</a
          >
        </template>
        <template #personalDataLink>
          <a
            :href="$t('shared.corporate.service.link.personalData.path')"
            target="_blank"
            rel="noopener noreferrer"
            class="permission-checks--link"
          >
            {{
              $t(
                "shared.corporate.permissionChecks.newsletter.personalDataLink",
              )
            }}
          </a>
        </template>
      </i18n-t>
    </CheckboxField>
  </div>
</template>

<style scoped>
@import "PermissionChecks.css";
</style>
