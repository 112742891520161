<script setup lang="ts">
import { ref } from "vue";
import { ColumnLayout, ColumnLayoutBox } from "@/shared/components";
import { StatusMessagePage } from "@/app/registration-app/shared/components";
import { RegistrationForm } from "@/app/registration-app/user/registration/components";
import { useRegisterUser } from "./useRegisterUser.composable";

// --- Double Opt-In ---
const showDoubleOptInInfo = ref(false);

// --- Register User ---
const { isRegisteringUser, onRegisteringUser } = useRegisterUser(() => {
  showDoubleOptInInfo.value = true;
});
</script>

<template>
  <StatusMessagePage
    v-if="showDoubleOptInInfo"
    :title="$t('registration.user.doubleOptIn.title')"
  >
    <template #text>
      <p>{{ $t("registration.user.doubleOptIn.info") }}</p>
      <p>{{ $t("registration.user.doubleOptIn.info2") }}</p>
    </template>
  </StatusMessagePage>
  <ColumnLayout v-else>
    <template #column1>
      <ColumnLayoutBox>
        <RegistrationForm
          :is-loading="isRegisteringUser"
          @register="onRegisteringUser"
        />
      </ColumnLayoutBox>
    </template>
  </ColumnLayout>
</template>
