export const DEFAULT_PAGE_SIZE = 25;
export const BOTTOM_BAR_MAX_ITEMS = 4;

export const POSTAL_CODE_LENGTH_DE = 5;
export const TEXTFIELD_MAX_LENGTH_SHORT = 10;
export const TEXTFIELD_MAX_LENGTH_DEFAULT = 30;
export const TEXTFIELD_MAX_LENGTH_MEDIUM = 35;
export const TEXTFIELD_MAX_LENGTH_EXTENDED = 40;
export const TEXTFIELD_MAX_LENGTH_LONG = 60;

export const SIDEBAR_EXPAND_DURATION = 500;

export const FRIENDLY_CAPTCHA_SITEKEY = "FCMO9JDHBH8JRVAQ";
export const FRIENDLY_CAPTCHA_PUZZLE_ENDPOINT =
  "https://eu-api.friendlycaptcha.eu/api/v1/puzzle";

export const DEV_JWT_KEY = "dev-jwt";
